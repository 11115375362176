import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { RootState } from "../../../store";
import { saveUserPost, saveUserPostViewed } from "../../../services/apiService";
import {
	fetchUserComments,
	fetchUserPosts,
} from "../../../store/User/UserSlice";

import moment from "moment";
import { useIsInViewport } from "../../../hooks/useIsInViewport";
import { Post, PostList } from "../../../@types/post.d";

import DisplayComments from "./DisplayComments";

import "./DisplayPost.scss";
import { ShareWithList } from "./ShareWithList";
import { RemoveFromList } from "./RemoveFromList";

export type PostProperties = {
	post: Post;
	index: number;
	allowComments?: boolean;
	allowEdit?: boolean;
	allowManage?: boolean;
	showStats?: boolean;
	trackViews?: boolean;
	listId?: string;
	onArchive?: ((postId: string) => void) | undefined;
	onUnArchive?: ((postId: string) => void) | undefined;
};

export const DisplayPost: React.FC<PostProperties> = ({
	post,
	allowEdit,
	allowManage,
	showStats,
	trackViews,
	listId,
	onArchive,
	onUnArchive,
}) => {
	const dispatch = useDispatch();

	const { userLists, userProfile } = useSelector(
		(state: RootState) => state.user
	);

	const [showComments, set_showComments] = useState<boolean>(false);
	const [showCommentBox, set_showCommentBox] = useState<boolean>(false);

	const toggleComments = () => set_showComments(!showComments);
	const toggleCommentBox = () => set_showCommentBox(!showCommentBox);

	const [listStatus, setListStatus] = useState<string | null>(null);
	const [postListId, setPostListId] = useState<string | null>(null);

	const sectionRef = useRef<any>(null);

	const isInViewport = useIsInViewport(sectionRef);

	const onArchiveClick = () => {
		if (onArchive) {
			onArchive(postListId as string);
		}
	};

	const onUnArchiveClick = () => {
		if (onUnArchive) {
			onUnArchive(postListId as string);
		}
	};

	useEffect(() => {
		if (post && trackViews) {
			if (!post.viewed && isInViewport) {
				saveUserPostViewed(userProfile.userDataId, post.postId, true);
			}
		}
	}, [post, isInViewport]);

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	useEffect(() => {
		if (listId && post.postLists.length) {
			// Find this instance
			let postList = post.postLists.find((pl) => pl.listId === listId);
			if (postList) {
				setListStatus(postList.status);
				setPostListId(postList.id);
			}
		}
	}, [listId, post]);

	const handleResize = (evt: any) => {};

	const takePostAction = (postId: any, action: any) => {
		if (action === "commentPrayed") {
			let newPost = {
				id: "00000000-0000-0000-0000-000000000000",
				title: "Prayed",
				content: "I prayed for this",
				listIds: null,
				status: "Active",
				parentPostId: postId,
				typeKey: 1,
			};
			saveUserPost(userProfile.userDataId, newPost).then((res: any) => {
				dispatch(fetchUserPosts(userProfile.userDataId));
				dispatch(fetchUserComments(userProfile.userDataId));
			});
		}
	};

	const setPostPrayed = (postId: string, prayed: boolean) => {
		takePostAction(postId, "commentPrayed");
	};

	const renderCategoryIcon = () => {
		let postList = post.postLists.find((pl) => pl.categoryId != null);

		let icon = "fa-praying-hands";
		if (postList?.categoryName) {
			switch (postList.categoryName) {
				case "Local":
					icon = "fa-city";
					break;
				case "Church Family":
					icon = "fa-church";
					break;
				case "Global":
					icon = "fa-earth-americas";
					break;
			}
		}
		return <i className={`text-primary fa-2x fas ${icon}`}></i>;
	};

	return (
		<Fragment>
			<Card
				ref={sectionRef}
				className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"
			>
				<Card.Body className="card-body p-0 d-flex">
					<figure className="avatar me-3">{renderCategoryIcon()}</figure>
					<h4 className="fw-700 text-grey-600 font-xssss mt-1">
						{post.authorFirstName && post.authorFirstName.length > 0 && (
							<span>
								&nbsp;by {post.authorFirstName}&nbsp;
								{post.authorLastName.length > 0
									? `${post.authorLastName[0]}.`
									: ""}
							</span>
						)}
						<span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
							{moment(post.created).format("MMM Do YYYY")}
						</span>
					</h4>
					<div className="ms-auto pointer">
						<Dropdown drop="down" className="ml-1">
							<Dropdown.Toggle variant="light-primary" id="dropdown-basic">
								<i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i>
								<span className="d-none-xs">Share</span>
							</Dropdown.Toggle>
							<Dropdown.Menu className="dropdown-menu dropdown-menu-bottom p-0 m-0">
								<Dropdown.Item className="mb-2" header>
									<span className="font-size-lg">Share with List:</span>
								</Dropdown.Item>
								{userLists &&
									userLists
										.filter((l) => l.canPost)
										.map((list: any, index: number) => {
											return (
												<Fragment key={index}>
													<Dropdown.Item className="mb-2" key={index}>
														<ShareWithList post={post} list={list} />
													</Dropdown.Item>
												</Fragment>
											);
										})}
							</Dropdown.Menu>
						</Dropdown>
					</div>
					{(allowEdit || allowManage) && (
						<div className="ms-3 pointer">
							<Dropdown drop="down" className="ml-1">
								<Dropdown.Toggle variant="light-primary" id="dropdown-basic">
									<i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
								</Dropdown.Toggle>
								<Dropdown.Menu className="dropdown-menu dropdown-menu-bottom p-0 m-0">
									{allowEdit && (
										<Dropdown.Item
											className="mb-2"
											href={`/edit-prayer/${post.postId ?? post.id}`}
										>
											<i className="fa fa-edit"></i>&nbsp;Edit
										</Dropdown.Item>
									)}
									{allowManage && listId && listStatus === "Active" && (
										<Dropdown.Item className="mb-2" onClick={onArchiveClick}>
											<i className="fa fa-archive"></i>&nbsp;Archive
										</Dropdown.Item>
									)}
									{allowManage && listId && listStatus === "Archived" && (
										<Dropdown.Item className="mb-2" onClick={onUnArchiveClick}>
											<i className="fa fa-archive"></i>&nbsp;Un Archive
										</Dropdown.Item>
									)}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					)}
				</Card.Body>
				{post.postVideo && (
					<Card.Body className="card-body p-3 mb-3 rounded-3 overflow-hidden uttam-die">
						<Link to="" className="video-btn">
							<video autoPlay loop className="float-right w-100">
								<source src={post.postVideo} type="video/mp4" />
							</video>
						</Link>
					</Card.Body>
				)}
				<Card.Body className="card-body p-3 me-2">
					<span
						className="fw-500 text-black-500 lh-26 font-md w-100 mb-0"
						dangerouslySetInnerHTML={{ __html: post.content }}
					></span>
				</Card.Body>
				{post.postImage && (
					<Card.Body className="card-body d-block p-3 mb-3">
						<div className="row ps-2 pe-2">
							<div className="col-sm-12 p-1">
								<img
									src={post.postImage}
									className="rounded-3 w-100"
									alt="post"
								/>
							</div>
						</div>
					</Card.Body>
				)}
				<Card.Body>
					{post?.postLists?.length > 0 && (
						<div className="d-flex">
							<div className="d-flex">
								<span className="fw-500 font-xsss">Shared with:</span>
							</div>
							<div className="ms-2 d-flex">
								<ul className="d-flex align-items-center justify-content-between comma-list">
									{post?.postLists.map((postList: PostList, index: number) => (
										<li className="me-1 font-xssss" key={index}>
											<RemoveFromList
												postId={postList.postId}
												listid={postList.listId}
												listName={postList.listName}
											/>
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
				</Card.Body>
				<Card.Body className="card-body d-flex p-0">
					<div className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss">
						<Link to="#" onClick={() => toggleComments()}>
							<i className="fa-lg fas fa-praying-hands text-white bg-primary-gradiant me-1 btn-round-xs font-xsss" />
							{post.prayedCount} prayed for this
						</Link>
					</div>
					<div className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ms-3">
						<Link to="#" onClick={() => toggleComments()}>
							<i className="fa-lg fas fa-comment text-primary bg-white me-1 btn-round-xs font-xs" />
							<span className="d-none-xss">{post.commentCount} comments</span>
						</Link>
					</div>
					<div className="ms-auto d-flex align-items-end">
						<Dropdown drop="down">
							<Dropdown.Toggle variant="light-primary" id="dropdown-basic">
								<i className="fa-lg fas fa-plus text-white bg-primary-gradiant btn-round-sm" />
							</Dropdown.Toggle>
							<Dropdown.Menu className="dropdown-menu dropdown-menu-bottom p-2 m-0 rounded-xxl">
								<Dropdown.Item className="mb-2">
									<Link
										to="#"
										onClick={() => setPostPrayed(post.postId, !post.prayed)}
									>
										<i className="fa-lg fas fa-praying-hands text-white bg-primary-gradiant me-1 btn-round-xs font-xsss" />
										&nbsp;Pray for this
									</Link>
								</Dropdown.Item>
								<Dropdown.Item className="mb-2">
									<Link to="#" onClick={() => toggleCommentBox()}>
										<i className="fa-lg fas fa-comment text-white bg-primary-gradiant me-1 btn-round-xs font-xsss" />
										&nbsp;Add comment
									</Link>
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</Card.Body>
				<DisplayComments
					post={post}
					className={`${showCommentBox ? "" : "flipped"}`}
					showComments={showComments}
					showCommentBox={showCommentBox}
					onSaveComment={() => set_showCommentBox(false)}
					onCancelComment={() => set_showCommentBox(false)}
				/>
				{showStats && (
					<div className="card-footer p-2">
						<Row>
							<Col className="text-left">
								<div className="p-3">
									<h6>Prayer Stats</h6>
									<ul>
										<li>
											<label>Prayed For:</label>&nbsp;
											{post.prayedCount}
										</li>
										<li>
											<label>Comments:</label>&nbsp;
											{post.commentCount}
										</li>
										<li>
											<label>Viewed:</label>&nbsp;
											{post.viewedCount}
										</li>
									</ul>
								</div>
							</Col>
						</Row>
					</div>
				)}
			</Card>
		</Fragment>
	);
};

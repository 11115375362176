import React from "react";
import { Card } from "react-bootstrap";

export const Load: React.FC = () => {
	return (
		<Card className="w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3">
			<div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
				<div className="stage">
					<div className="dot-typing"></div>
				</div>
			</div>
		</Card>
	);
};

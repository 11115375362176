import { Fragment } from "react";
import { Link, Route } from "react-router-dom";

import { UserProfile } from "..";
import Pagetitle from "../shared/components/Pagetitle";
import { UserPassword } from "./components/UserPassword";

const Profile = () => {
	return (
		<Fragment>
			<Route path="/settings/user">
				<UserProfile signup={false} />
			</Route>
			<Route path="/settings/password">
				<UserPassword />
			</Route>
			<Route path="/settings" exact>
				<Pagetitle title="Settings" />
				<div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
					<div className="card-body p-lg-5 p-4 w-100 border-0">
						<div className="row">
							<div className="col-lg-12">
								<ul className="list-inline mb-4">
									<li className="list-inline-item d-block border-bottom me-0">
										<Link
											to="/settings/user"
											className="pt-2 pb-2 d-flex align-items-center"
										>
											<i className="btn-round-md bg-primary-gradiant text-white feather-home font-md me-3"></i>{" "}
											<h4 className="fw-600 font-xsss mb-0 mt-0">
												User Information
											</h4>
											<i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
										</Link>
									</li>
									<li className="list-inline-item d-block me-0">
										<Link
											to="/settings/password"
											className="pt-2 pb-2 d-flex align-items-center"
										>
											<i className="btn-round-md bg-blue-gradiant text-white fas fa-unlock-keyhole font-md me-3"></i>{" "}
											<h4 className="fw-600 font-xsss mb-0 mt-0">Password</h4>
											<i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
										</Link>
									</li>
									{/* <li className="list-inline-item d-block me-0">
										<Link
											to="/contactinformation"
											className="pt-2 pb-2 d-flex align-items-center"
										>
											<i className="btn-round-md bg-blue-gradiant text-white fas fa-gears font-md me-3"></i>{" "}
											<h4 className="fw-600 font-xsss mb-0 mt-0">
												Account Settings
											</h4>
											<i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
										</Link>
									</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</Route>
		</Fragment>
	);
};

export default Profile;

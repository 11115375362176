import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const NavSlice = createSlice({
	name: "NavigationSlice",
	initialState: {
		uiTheme: "theme-light",
		invitationCode: "",
	},
	reducers: {
		setUITheme(state: any, action: PayloadAction<string>) {
			state.uiTheme = action.payload;
		},
		setInvitationCode(state: any, action: PayloadAction<string>) {
			state.invitationCode = action.payload;
		},
	},
	extraReducers: (builder) => {},
});

export const { setUITheme, setInvitationCode } = NavSlice.actions;

export default NavSlice.reducer;

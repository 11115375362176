import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const UserPassword: React.FC = () => {
	return (
		<Fragment>
			<Card className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
				<Card.Body className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
					<Link to="/settings" className="d-inline-flex mt-2 pointer">
						<i className="ti-arrow-left font-sm text-white"></i>
						<h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-0">
							Change Password
						</h4>
					</Link>
				</Card.Body>
				<Card.Body className="p-lg-5 p-4 w-100 border-0">
					<form action="#">
						<Row>
							<div className="col-lg-12 mb-3">
								<div className="form-gorup">
									<label className="mont-font fw-600 font-xssss">
										Current Password
									</label>
									<input
										type="text"
										name="comment-name"
										className="form-control"
									/>
								</div>
							</div>

							<div className="col-lg-12 mb-3">
								<div className="form-gorup">
									<label className="mont-font fw-600 font-xssss">
										Change Password
									</label>
									<input
										type="text"
										name="comment-name"
										className="form-control"
									/>
								</div>
							</div>
						</Row>
						<Row>
							<div className="col-lg-12 mb-3">
								<div className="form-gorup">
									<label className="mont-font fw-600 font-xssss">
										Confirm Change Password
									</label>
									<input
										type="text"
										name="comment-name"
										className="form-control"
									/>
								</div>
							</div>
						</Row>
						<Row>
							<div className="col-lg-12 mb-0">
								<a
									href="/password"
									className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
								>
									Save
								</a>
							</div>
						</Row>
					</form>
				</Card.Body>
			</Card>
		</Fragment>
	);
};

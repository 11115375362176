import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import {
	Button,
	Col,
	Container,
	Form,
	Modal,
	ModalBody,
	ModalFooter,
	OverlayTrigger,
	Row,
	Tooltip,
} from "react-bootstrap";

import { saveList } from "../../../services/apiService";
import AppEmitter from "../../../services/appEmitter";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
	fetchUserComments,
	fetchUserLists,
	fetchUserPosts,
} from "../../../store/User/UserSlice";
import { List } from "../../../@types/list.d";

const NewListButton = () => {
	const dispatch = useDispatch();
	const { userProfile } = useSelector((state: RootState) => state.user);

	const [addListName, setAddListName] = useState("");
	const [addListDescription, setAddListDescription] = useState("");
	const [newModal, setNewModal] = useState(false);

	const toggleAdd = () => {
		AppEmitter.emit("SHOW_MOBILE_MENU", false);
		setNewModal(!newModal);
	};

	const addList = () => {
		let newList: List = {
			id: "00000000-0000-0000-0000-000000000000",
			name: addListName,
			description: addListDescription,
			status: "Active",
			userId: userProfile.userDataId,
			isPublic: false,
			posts: [],
		};

		saveList(newList).then((res) => {
			dispatch(fetchUserPosts(userProfile.userDataId));
			dispatch(fetchUserComments(userProfile.userDataId));
			dispatch(fetchUserLists(userProfile.userDataId));
		});
		setNewModal(false);
	};

	return (
		<Fragment>
			<Modal show={newModal} toggle={toggleAdd} className="">
				<Modal.Header>
					<h2>Add new list</h2>
				</Modal.Header>
				<ModalBody>
					<Form id="kt_contact_add_form">
						<Row className="form-group">
							<Col sm="12">
								<label className="col-form-label">List Name</label>
							</Col>
							<Col sm="12">
								<input
									type="text"
									className="form-control form-control-lg form-control-solid"
									name="listName"
									placeholder="List name"
									value={addListName}
									onChange={(e) => setAddListName(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="form-group">
							<Col sm="12">
								<label className="col-form-label">Description</label>
							</Col>
							<Col sm="12">
								<textarea
									className="form-control form-control-lg form-control-solid h200"
									name="description"
									rows={5}
									placeholder="Description"
									value={addListDescription}
									onChange={(e) => setAddListDescription(e.target.value)}
								/>
							</Col>
						</Row>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button
						className="bg-white text-center text-gray font-xsss fw-600 p-2 m-2 rounded-3 d-inline-block"
						onClick={() => setNewModal(false)}
					>
						Cancel
					</Button>
					<Button
						className="bg-red text-center text-white font-xsss fw-600 p-2 m-2 rounded-3 d-inline-block"
						onClick={() => addList()}
					>
						Save List
					</Button>
				</ModalFooter>
			</Modal>
			<OverlayTrigger
				placement={"bottom"}
				overlay={<Tooltip id={`tooltip-left`}>Add New List</Tooltip>}
			>
				<Button
					className="ms-auto d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark"
					onClick={() => setNewModal(true)}
				>
					<i className="fas fa-plus font-md text-white"></i>
				</Button>
			</OverlayTrigger>
		</Fragment>
	);
};

export default NewListButton;

import { Fragment, useEffect, useState } from "react";
import { Card, Container, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { List } from "../../@types/list.d";
import { Post } from "../../@types/post.d";
import { getListById, setPostListStatus } from "../../services/apiService";
import PageTitle from "../shared/components/Pagetitle";
import { ManageListCategories } from "./components/ManageListCategories";
import { ManagePost } from "./components/ManagePost";

export type ListParams = {
	listId: string;
};

const ManageList = () => {
	const [key, setKey] = useState<string>("active");
	const [displayTitle, setDisplayTitle] = useState("");
	const [displayOrg, setDisplayOrg] = useState("");
	const [activePosts, setActivePosts] = useState<Array<any>>([]);
	const [archivedPosts, setArchivedPosts] = useState<Array<any>>([]);
	const { listId } = useParams<ListParams>();

	const filterPosts = (posts: Array<Post>) => {
		let act = posts.filter((p) => {
			let postList = p.postLists?.find((l) => l.listId === listId);
			if (postList) {
				return postList.status === "Active";
			}
		});
		let arch = posts.filter((p) => {
			let postList = p.postLists?.find((l) => l.listId === listId);
			if (postList) {
				return postList.status === "Archived";
			}
		});
		setActivePosts(act);
		setArchivedPosts(arch);
	};

	const loadList = (listId: string) => {
		getListById(listId).then((targetList: List) => {
			if (targetList) {
				setDisplayTitle(targetList.name);
				setDisplayOrg(targetList?.org?.name ?? "");
				if (targetList.posts?.length) {
					filterPosts(targetList.posts);
				}
			}
		});
	};

	const archivePost = async (postListId: string) => {
		await setPostListStatus(postListId, "Archived");
		loadList(listId);
	};

	const unArchivePost = async (postListId: string) => {
		await setPostListStatus(postListId, "Active");
		loadList(listId);
	};

	useEffect(() => {
		if (listId) {
			loadList(listId);
		}
	}, [listId]);

	return (
		<Fragment>
			<PageTitle title={`Manage List: ${displayTitle}`} />
			<Tabs
				id="controlled-tab-example"
				activeKey={key}
				onSelect={(key) => setKey(key ?? "")}
			>
				<Tab eventKey="active" title="Active">
					{activePosts && activePosts.length > 0 ? (
						<div id="list-panel">
							{activePosts.map((post, index) => {
								return (
									<Fragment key={index}>
										<ManagePost
											post={post}
											index={index}
											listId={listId}
											onArchive={(postListId) => archivePost(postListId)}
										/>
									</Fragment>
								);
							})}
						</div>
					) : (
						<Card className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
							<Card.Body>
								<div className="d-flex flex-column flex-grow-1">
									<span className="text-dark-75 mb-1 font-size-lg font-weight-bolder">
										No Posts to display
									</span>
								</div>
							</Card.Body>
						</Card>
					)}
				</Tab>
				<Tab eventKey="archived" title="Archived">
					{archivedPosts && archivedPosts.length > 0 ? (
						<div id="list-panel">
							{archivedPosts.map((post, index) => {
								return (
									<Fragment key={index}>
										<ManagePost
											post={post}
											index={index}
											listId={listId}
											onUnArchive={(postListId) => unArchivePost(postListId)}
										/>
									</Fragment>
								);
							})}
						</div>
					) : (
						<Card className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
							<Card.Body>
								<div className="d-flex flex-column flex-grow-1">
									<span className="text-dark-75 mb-1 font-size-lg font-weight-bolder">
										No Posts to display
									</span>
								</div>
							</Card.Body>
						</Card>
					)}
				</Tab>
				<Tab eventKey="categories" title="Categories">
					<div className="card card-custom gutter-b">
						<div className="card-body">
							<div className="d-flex flex-column flex-grow-1">
								<span className="text-dark-75 mb-1 font-size-lg font-weight-bolder">
									<ManageListCategories />
								</span>
							</div>
						</div>
					</div>
				</Tab>
			</Tabs>
		</Fragment>
	);
};

export default ManageList;

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './User/UserSlice';
import listReducer from './List/ListSlice';
import navReducer from './Navigation/NavSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        list: listReducer,
        nav: navReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
import { Fragment, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "./shared/Spinner";

export function Logout() {
	const { logout } = useAuth0();

	useEffect(() => {
		localStorage.removeItem("idToken");

		logout({
			returnTo: window.location.origin,
		});
	}, []);

	return <Fragment></Fragment>;
}

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";

import { RootState } from "../../../store";
import { saveUserPost } from "../../../services/apiService";
import {
	fetchUserComments,
	fetchUserPosts,
} from "../../../store/User/UserSlice";

export const CreatePost: React.FC = () => {
	const dispatch = useDispatch();

	const [isOpen, set_isOpen] = useState<boolean>(false);
	const [prayerContent, set_prayerContent] = useState<string>("");

	const toggleOpen = () => set_isOpen(!isOpen);

	const { userProfile, userPosts, userLists } = useSelector(
		(state: RootState) => state.user
	);

	const menuClass = `${isOpen ? " show" : ""}`;
	const savePrayer = () => {
		let editedPost = {
			id: "00000000-0000-0000-0000-000000000000",
			title: "",
			content: prayerContent,
			listIds: [],
			status: "Active",
			parentPostId: null,
			typeKey: 1,
		};

		saveUserPost(userProfile.userDataId, editedPost).then((res: any) => {
			dispatch(fetchUserPosts(userProfile.userDataId));
			dispatch(fetchUserComments(userProfile.userDataId));
			set_prayerContent("");
		});
	};

	return (
		<div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
			<div className="card-body p-0">
				<i className="btn-round-sm font-xs text-primary feather-edit me-2 bg-greylight"></i>
				New Prayer
			</div>
			<div className="card-body p-0 mt-3 position-relative">
				<textarea
					name="message"
					className="h100 bor-0 w-100 rounded-xxl p-2 font-xssss text-grey-600 fw-500 border-light-md theme-dark-bg"
					placeholder="What do you need to pray for?"
					value={prayerContent}
					onChange={(e) => set_prayerContent(e.target.value)}
				></textarea>
			</div>
			<div className="card-body d-flex p-0 mt-0">
				<Button
					className="ms-auto d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark"
					onClick={savePrayer}
				>
					<i className="fas fa-plus font-md text-white"></i>
					<span className="d-none-xs text-white ps-2">Post</span>
				</Button>
			</div>
		</div>
	);
};

import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { RootState } from "../../../store";
import { Post } from "../../../@types/post.d";
import { saveUserPost, sortPostsDesc } from "../../../services/apiService";
import {
	fetchUserComments,
	fetchUserPosts,
} from "../../../store/User/UserSlice";

import "./DisplayComments.scss";

const CommentContent = (props: any) => {
	const { post, index } = props;
	const { userProfile } = useSelector((state: RootState) => state.user);

	useEffect(() => {}, []);

	return (
		<div className="timeline-item" key={index}>
			<div className="timeline-label">
				{moment(post.created).format("MMM Do YYYY")}
			</div>
			<div className="timeline-badge">
				{post.title == "Comment" && (
					<i className="fa fa-comment text-primary "></i>
				)}
				{post.title == "Prayed" && (
					<i className="fa fa-praying-hands text-primary "></i>
				)}
			</div>
			<div className="timeline-content text-muted font-weight-normal">
				{post.title === "Comment" && (
					<Fragment>
						<div>{post.content}</div>
						{userProfile.userDataId === post.ownerId ? (
							<small>
								- {post.authorFirstName}&nbsp;
								{post.authorLastName.length > 0
									? `${post.authorLastName[0]}.`
									: ""}
								(creator)
							</small>
						) : (
							<small>
								- {post.authorFirstName}&nbsp;
								{post.authorLastName.length > 0
									? `${post.authorLastName[0]}.`
									: ""}
							</small>
						)}
					</Fragment>
				)}
				{post.title === "Prayed" && (
					<Fragment>
						{userProfile.userDataId === post.ownerId ? (
							<span>
								{post.authorFirstName}&nbsp;
								{post.authorLastName.length > 0
									? `${post.authorLastName[0]}.`
									: ""}
								&nbsp;(creator) prayed for this
							</span>
						) : (
							<span>
								{post.authorFirstName}&nbsp;
								{post.authorLastName.length > 0
									? `${post.authorLastName[0]}.`
									: ""}
								prayed for this
							</span>
						)}
					</Fragment>
				)}
			</div>
		</div>
	);
};

type DisplayCommentParams = {
	post: Post;
	className: string;
	showComments: boolean;
	showCommentBox: boolean;
	onSaveComment?: (() => void) | undefined;
	onCancelComment?: (() => void) | undefined;
};

const DisplayComments: React.FC<DisplayCommentParams> = ({
	post,
	className,
	showComments,
	showCommentBox,
	onSaveComment,
	onCancelComment,
}) => {
	const dispatch = useDispatch();
	const { userComments, userProfile } = useSelector(
		(state: RootState) => state.user
	);
	const [postComments, setPostComments] = useState<Array<Post>>([]);
	const [newComment, setNewComment] = useState<string>("");

	const inputComment: any = useRef(null);

	const takePostAction = (postId: any, action: any) => {
		if (action === "sendResponse") {
			let newPost = {
				id: "00000000-0000-0000-0000-000000000000",
				title: "Comment",
				content: inputComment.current.value,
				listIds: null,
				status: "Active",
				parentPostId: postId,
				created: null,
				modified: null,
				typeKey: 1,
			};
			saveUserPost(userProfile.userDataId, newPost).then((res: any) => {
				if (onSaveComment) onSaveComment();
				setNewComment("");
				dispatch(fetchUserPosts(userProfile.userDataId));
				dispatch(fetchUserComments(userProfile.userDataId));
			});
		}
	};

	useEffect(() => {
		if (userComments) {
			var comments = userComments.filter((pc: any) => {
				// return (post.postId === pc.parentPostId && pc.title !== 'Prayed');
				return post.postId === pc.parentPostId;
			});
			setPostComments(sortPostsDesc(comments));
		}
	}, [userComments]);

	return (
		<div className={`prayer-comments ${className}`}>
			{showCommentBox && (
				<Fragment>
					<form className="position-relative">
						<textarea
							id="kt_forms_widget_6_input"
							className="form-control border-1 p-2 pr-10 resize-none h75"
							placeholder="Add comment..."
							value={newComment}
							onChange={(e) => setNewComment(e.target.value)}
							ref={inputComment}
						></textarea>
						<div className="position-absolute top-0 right-0 mt-n1 mr-n2">
							<a
								className="btn btn-icon btn-sm btn-hover-icon-primary mt-4"
								title="Send response"
								onClick={() => takePostAction(post.postId, "sendResponse")}
							>
								<i className="fas fa-paper-plane"></i>
							</a>
							<a
								className="btn btn-icon btn-sm btn-hover-icon-danger mt-4 mr-3"
								title="Send response"
								onClick={() => {
									if (onCancelComment) onCancelComment();
								}}
							>
								<i className="fas fa-ban"></i>
							</a>
						</div>
					</form>
					<div className="separator separator-solid mt-2 mb-4"></div>
				</Fragment>
			)}
			{showComments && (
				<Fragment>
					{postComments && postComments.length > 0 ? (
						<div className="timeline timeline-1 m-3">
							<div className="timeline-sep bg-primary-opacity-20"></div>
							{postComments?.map((c: any, index: number) => (
								<Fragment key={index}>
									<CommentContent post={c} index={index} />
								</Fragment>
							))}
						</div>
					) : (
						<i>Comments may only be seen by the creator.</i>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default DisplayComments;

import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import { RootState } from "../../../store";

import {
	fetchUserComments,
	fetchUserLists,
	fetchUserPosts,
} from "../../../store/User/UserSlice";
import { saveList } from "../../../services/apiService";
import AppEmitter from "../../../services/appEmitter";
import { List } from "../../../@types/list.d";
import { useHistory } from "react-router-dom";
import PageTitle from "../../shared/components/Pagetitle";

const EditList = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { userProfile } = useSelector((state: RootState) => state.user);

	const [addListName, setAddListName] = useState("");
	const [addListDescription, setAddListDescription] = useState("");
	const [newModal, setNewModal] = useState(false);

	const toggleAdd = () => {
		AppEmitter.emit("SHOW_MOBILE_MENU", false);
		setNewModal(!newModal);
	};

	const addList = () => {
		let newList: List = {
			id: "00000000-0000-0000-0000-000000000000",
			name: addListName,
			description: addListDescription,
			status: "Active",
			userId: userProfile.userDataId,
			isPublic: false,
			posts: [],
		};

		saveList(newList).then((res) => {
			dispatch(fetchUserPosts(userProfile.userDataId));
			dispatch(fetchUserComments(userProfile.userDataId));
			dispatch(fetchUserLists(userProfile.userDataId));
		});
		setNewModal(false);

		history.push("/my-lists");
	};

	const cancelPrayer = () => {
		history.goBack();
		// history.push('/prayers');
	};

	return (
		<Fragment>
			<PageTitle title="New List" />
			<Row>
				<Col>
					<div className="card card-custom">
						<form className="form">
							<div className="card-body text-left">
								<Row className="form-group">
									<Col sm="12">
										<label className="col-form-label">List Name</label>
									</Col>
									<Col sm="12">
										<input
											type="text"
											className="form-control form-control-lg form-control-solid"
											name="listName"
											value={addListName}
											onChange={(e) => setAddListName(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="form-group">
									<Col sm="12">
										<label className="col-form-label">Description</label>
									</Col>
									<Col sm="12">
										<textarea
											className="form-control form-control-lg form-control-solid h200"
											name="description"
											rows={5}
											value={addListDescription}
											onChange={(e) => setAddListDescription(e.target.value)}
										/>
									</Col>
								</Row>
								<div className="card-footer text-center">
									<Button
										type="reset"
										color="secondary"
										className="bg-white text-center text-gray font-xsss fw-600 p-2 m-2 rounded-3 d-inline-block"
										onClick={() => cancelPrayer()}
									>
										Cancel
									</Button>
									<Button
										type="button"
										color="primary"
										className="bg-red text-center text-white font-xsss fw-600 p-2 m-2 rounded-3 d-inline-block"
										onClick={() => addList()}
									>
										Save
									</Button>
								</div>
							</div>
						</form>
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};

export default EditList;

import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Row, Col, Container, Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { RootState } from "../../../store";

import { sortPostsDesc } from "../../../services/apiService";
import { setNumPosts } from "../../../store/User/UserSlice";

import PrayerCardNavButtons from "./PrayerCardNavButtons";
import { DisplayPost } from "./DisplayPost";
import PageTitle from "../../shared/components/Pagetitle";
import { CreatePost } from "./Createpost";

type ListParams = {
	listId: string;
};

const DisplayPosts = (userId: any) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { userProfile, userPosts, userLists, currentIndex } = useSelector(
		(state: RootState) => state.user
	);
	const { listId } = useParams<ListParams>();

	const [displayTitle, setDisplayTitle] = useState("All Prayers From My Lists");
	const [displayOrg, setDisplayOrg] = useState("");
	const [displayPosts, setDisplayPosts] = useState<Array<any>>([]);
	const [allLists, setAllLists] = useState<boolean>();
	const [filterLists, setFilterLists] = useState<Array<string>>([]);

	const toggleListFilter = (e: any, listId: string) => {
		e.preventDefault();
		e.stopPropagation();

		var array = [...filterLists]; // make a separate copy of the array
		var index = array.indexOf(listId);
		if (index !== -1) {
			array.splice(index, 1);
		} else {
			array.push(listId);
		}
		filterPosts(array);
		setFilterLists(array);
	};

	const navigateToList = (listId: string) => {
		if (listId) {
			history.push(`/all-prayers/${listId}`);
		} else {
			history.push(`/all-prayers`);
		}
	};

	const filterPosts = (ids: Array<string>) => {
		if (!ids) return;

		let posts = userPosts?.filter((x: any) => {
			if (!listId && x.postLists.length === 0) {
				// Private prayers, show this one
				return true;
			}

			var postLists = x.postLists?.filter((pl: any) => {
				var index = ids.indexOf(pl.listId);
				return index > -1;
			});
			return postLists.length > 0;
		});
		dispatch(setNumPosts(posts.length));

		setDisplayPosts(sortPostsDesc(posts));
	};

	useEffect(() => {
		// dispatch(setCurrentIndex(0));
		if (listId == null || listId === "") {
			let ids = userLists?.map((item: any) => {
				return item.id;
			});
			setAllLists(true);
			setFilterLists(ids);
			filterPosts(ids);
		} else {
			let targetList = userLists?.filter((item: any) => {
				return listId === item.id;
			})[0];
			if (targetList) {
				setDisplayTitle(targetList.name);
				setDisplayOrg(targetList?.org?.name ?? "");
			}
			setAllLists(false);
			setFilterLists([listId]);
			filterPosts([listId]);
		}
	}, [userPosts, userLists, listId]);

	return (
		<Fragment>
			<PageTitle
				title={displayTitle}
				dropdown={
					<Dropdown drop="left" className="ml-1">
						<Dropdown.Toggle variant="light-primary" id="dropdown-basic">
							<i className="fas fa-filter fa-lg text-primary"></i>
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu dropdown-menu-bottom p-0 m-0">
							<Dropdown.Item header>
								<span className="font-size-lg">Filter by List:</span>
							</Dropdown.Item>
							<Dropdown.Item onClick={(evt: any) => navigateToList("")}>
								{allLists ? (
									<i className="fa-lg fas fa-check-circle text-primary"></i>
								) : (
									<i className="fa-lg fas fa-check-circle"></i>
								)}
								<span></span>&nbsp;&nbsp;All My Lists
							</Dropdown.Item>
							{userLists &&
								userLists.map((list: any, index: number) => {
									return (
										<Fragment key={index}>
											<Dropdown.Item
												key={index}
												onClick={
													(evt: any) => navigateToList(list.id)
													// toggleListFilter(evt, list.id)
												}
											>
												{filterLists && filterLists.indexOf(list.id) >= 0 ? (
													<i className="fa-lg fas fa-check-circle text-primary"></i>
												) : (
													<i className="fa-lg fas fa-check-circle"></i>
												)}
												<span></span>&nbsp;&nbsp;{list.name}
												{list.org?.name && (
													<span>&nbsp;-&nbsp;{list.org?.name}</span>
												)}
											</Dropdown.Item>
										</Fragment>
									);
								})}
						</Dropdown.Menu>
					</Dropdown>
				}
			/>
			<Fragment>
				<Row>
					<Col>
						<div id="prayer-panel">
							<CreatePost />
							{displayPosts && displayPosts.length > 0 ? (
								<Fragment>
									{displayPosts.map((post, index) => {
										return (
											<Fragment key={index}>
												<DisplayPost
													post={post}
													index={index}
													allowComments={true}
													allowEdit={userProfile.userDataId === post.ownerId}
													allowManage={userProfile.userDataId === post.ownerId}
													trackViews={true}
												/>
											</Fragment>
										);
									})}
								</Fragment>
							) : (
								<Card className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
									<Card.Body>
										<div className="d-flex flex-column flex-grow-1">
											<span className="text-dark-75 mb-1 font-size-lg font-weight-bolder">
												No Posts to display
											</span>
										</div>
									</Card.Body>
								</Card>
							)}
						</div>
					</Col>
				</Row>
			</Fragment>
		</Fragment>
	);
};

export default DisplayPosts;

import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	Row,
	Tab,
	Table,
	Tabs,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { useAuth0, IdToken } from "@auth0/auth0-react";

import { RootState } from "../../../store";

import {
	fetchUserComments,
	fetchUserLists,
	fetchUserPosts,
	setProfileStatus,
	setUserProfile,
} from "../../../store/User/UserSlice";
import { getUser, saveUserProfile } from "../../../services/apiService";

import "./UserProfile.css";
import { UserData } from "../../../@types/user.d";
import JoinList from "../../lists/components/JoinList";

export type Props = {
	signup: boolean;
};

const UserProfile: React.FC<Props> = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { userProfile, userLists } = useSelector(
		(state: RootState) => state.user
	);
	const { publicLists } = useSelector((state: RootState) => state.list);

	const [activeStep, setActiveStep] = useState<string>("info");
	const [firstName, setFirstName] = useState(userProfile?.firstName ?? "");
	const [lastName, setLastName] = useState(userProfile?.lastName ?? "");
	const [contactPhone, setContactPhone] = useState("");
	const [churchName, setChurchName] = useState("");
	const [churchWebsite, setChurchWebsite] = useState("");

	const { user, getIdTokenClaims } = useAuth0();

	const onPrev = () => {
		switch (activeStep) {
			case "info":
				return;
			case "settings":
				setActiveStep("info");
				return;
			case "lists":
				setActiveStep("settings");
				return;
			case "review":
				setActiveStep("lists");
				return;
		}
	};

	const onNext = () => {
		switch (activeStep) {
			case "info":
				setActiveStep("settings");
				return;
			case "settings":
				setActiveStep("lists");
				return;
			case "lists":
				setActiveStep("review");
				return;
			case "review":
				return;
		}
	};

	const onCancel = () => {
		history.push("/");
	};

	const onSave = async () => {
		// Save profile
		let upUpdate: UserData = {
			userDataId: userProfile.userDataId,
			identityId: user?.sub as string,
			firstName: firstName ?? "",
			lastName: lastName ?? "",
			emailAddress: user?.email as string,
		};

		await saveUserProfile(user?.sub as string, upUpdate).then((res) => {
			let claims = getIdTokenClaims();
			getUser(user?.sub as string).then((userProfile: UserData) => {
				if (
					userProfile.firstName.length === 0 ||
					userProfile.lastName.length === 0
				) {
					// dispatch(setProfileStatus("Incomplete"));
				} else {
					dispatch(setUserProfile(JSON.stringify(userProfile)));
					// dispatch(setProfileStatus("Complete"));

					dispatch(fetchUserPosts(userProfile.userDataId));
					dispatch(fetchUserComments(userProfile.userDataId));
					dispatch(fetchUserLists(userProfile.userDataId));
				}
			});
		});

		// Just say done for now.
		dispatch(setProfileStatus("Complete"));
	};

	const renderWizardSteps = () => (
		<Row>
			<Col sm="3" xs="12">
				<div
					className="wizard-step mt-5"
					data-wizard-type="step"
					data-wizard-state={activeStep === "info" ? "current" : ""}
				>
					<div className="wizard-label">
						<i className="fas fa-3x fa-user" />
						<h3 className="wizard-title">1. Personal Information</h3>
					</div>
					<i className="fa fa-arrow-right fa-lg"></i>
				</div>
			</Col>
			<Col sm="3" xs="12">
				<div
					className="wizard-step mt-5"
					data-wizard-type="step"
					data-wizard-state={activeStep === "settings" ? "current" : ""}
				>
					<div className="wizard-label">
						<i className="fas fa-3x fa-gear" />
						<h3 className="wizard-title">2. Account Settings</h3>
					</div>
					<i className="fa fa-arrow-right fa-lg"></i>
				</div>
			</Col>
			<Col sm="3" xs="12">
				<div
					className="wizard-step mt-5"
					data-wizard-type="step"
					data-wizard-state={activeStep === "lists" ? "current" : ""}
				>
					<div className="wizard-label">
						<i className="fas fa-3x fa-globe" />
						<h3 className="wizard-title">
							3. Subscribe to
							<br />
							prayer lists
						</h3>
					</div>
					<i className="fa fa-arrow-right fa-lg"></i>
				</div>
			</Col>
			<Col sm="3" xs="12">
				<div
					className="wizard-step mt-5"
					data-wizard-type="step"
					data-wizard-state={activeStep === "review" ? "current" : ""}
				>
					<div className="wizard-label">
						<i className="fas fa-3x fa-check" />
						<h3 className="wizard-title">4. Ready to Pray!</h3>
					</div>
				</div>
			</Col>
		</Row>
	);

	const renderInfoSection = () => (
		<Fragment>
			{(!props.signup || activeStep === "info") && (
				<div className="pb-5" data-wizard-type="step-content">
					<Row>
						<Col xs="12">
							{props.signup ? (
								<div className="col-lg-9 col-xl-6 mt-5">
									<h1 className="mb-10 font-weight-bold text-dark">
										Personal Information
									</h1>
								</div>
							) : (
								<div className="row justify-content-center">
									<div className="col-lg-4 text-center">
										<figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
											<img
												src="https://via.placeholder.com/300x300.png"
												alt="avater"
												className="shadow-sm rounded-3 w-100"
											/>
										</figure>
										<h2 className="fw-700 font-sm text-grey-900 mt-3">
											{userProfile.firstName}
										</h2>
										<h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">
											{userProfile.lastName}
										</h4>
									</div>
								</div>
							)}
							<div className="row">
								<div className="col-lg-6 mb-3">
									<div className="form-group">
										<label className="mont-font fw-600 font-xsss mb-2">
											First Name
										</label>
										<input
											type="text"
											className="form-control form-control-lg form-control-solid"
											name="firstname"
											placeholder="First name"
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
										/>
									</div>
								</div>

								<div className="col-lg-6 mb-3">
									<div className="form-group">
										<label className="mont-font fw-600 font-xsss mb-2">
											Last Name
										</label>
										<input
											type="text"
											className="form-control form-control-lg form-control-solid"
											name="lastname"
											placeholder="Last name"
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6 mb-3">
									<div className="form-group">
										<label className="mont-font fw-600 font-xsss mb-2">
											Email
										</label>
										<div className="input-group input-group-lg input-group-solid">
											<div className="input-group-prepend">
												<span className="input-group-text p-3">
													<i className="fas fa-at"></i>
												</span>
											</div>
											<input
												type="text"
												className="form-control form-control-lg form-control-solid"
												name="email"
												placeholder="Email"
												value={user?.email}
												readOnly
											/>
										</div>
										<span className="form-text text-muted">
											We'll never share your email.
										</span>
									</div>
								</div>
								<div className="col-lg-6 mb-3">
									<div className="form-group">
										<label className="mont-font fw-600 font-xsss mb-2">
											Phone
										</label>
										<div className="input-group input-group-lg input-group-solid">
											<div className="input-group-prepend">
												<span className="input-group-text p-3">
													<i className="fa fa-phone"></i>
												</span>
											</div>
											<input
												type="text"
												className="form-control form-control-lg form-control-solid"
												placeholder="Phone"
												value={contactPhone}
												onChange={(e) => setContactPhone(e.target.value)}
											/>
										</div>
										<span className="form-text text-muted">
											We'll never share your phone number.
										</span>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			)}
		</Fragment>
	);

	const renderSettingsSection = () => (
		<Fragment>
			{(!props.signup || activeStep === "settings") && (
				<div className="pb-5" data-wizard-type="step-content">
					<div className="row">
						<div className="col-xl-12">
							<div className="form-group row">
								<div className="col-lg-9 col-xl-6 mt-5">
									<h1 className="mb-10 font-weight-bold text-dark">
										Account Settings
									</h1>
								</div>
							</div>
							<div className="form-group row">
								<label className="col-xl-3 col-lg-3 col-form-label">
									Language
								</label>
								<div className="col-lg-9 col-xl-9">
									<select
										name="language"
										className="form-control form-control-lg form-control-solid"
									>
										<option value="">Select Language...</option>
										<option value="de">Deutsch - German</option>
										<option value="en" selected={true}>
											English
										</option>
										<option value="en-gb">English UK - British English</option>
										<option value="es">Español - Spanish</option>
										<option value="ru">Русский - Russian</option>
										<option value="he">עִבְרִית - Hebrew</option>
										<option value="ar">العربية - Arabic</option>
										<option value="fa">فارسی - Persian</option>
										<option value="zh-cn">简体中文 - Simplified Chinese</option>
										<option value="zh-tw">
											繁體中文 - Traditional Chinese
										</option>
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label className="col-xl-3 col-lg-3 col-form-label">
									Time Zone
								</label>
								<div className="col-lg-9 col-xl-9">
									<select
										name="timezone"
										className="form-control form-control-lg form-control-solid"
									>
										<option
											data-offset="-39600"
											value="International Date Line West"
										>
											(GMT-11:00) International Date Line West
										</option>
										<option data-offset="-39600" value="Midway Island">
											(GMT-11:00) Midway Island
										</option>
										<option data-offset="-39600" value="Samoa">
											(GMT-11:00) Samoa
										</option>
										<option data-offset="-36000" value="Hawaii">
											(GMT-10:00) Hawaii
										</option>
										<option data-offset="-28800" value="Alaska">
											(GMT-08:00) Alaska
										</option>
										<option
											data-offset="-25200"
											value="Pacific Time (US &amp; Canada)"
										>
											(GMT-07:00) Pacific Time (US &amp; Canada)
										</option>
										<option data-offset="-25200" value="Tijuana">
											(GMT-07:00) Tijuana
										</option>
										<option data-offset="-25200" value="Arizona">
											(GMT-07:00) Arizona
										</option>
										<option
											data-offset="-21600"
											value="Mountain Time (US &amp; Canada)"
										>
											(GMT-06:00) Mountain Time (US &amp; Canada)
										</option>
										<option data-offset="-21600" value="Chihuahua">
											(GMT-06:00) Chihuahua
										</option>
										<option data-offset="-21600" value="Mazatlan">
											(GMT-06:00) Mazatlan
										</option>
										<option data-offset="-21600" value="Saskatchewan">
											(GMT-06:00) Saskatchewan
										</option>
										<option data-offset="-21600" value="Central America">
											(GMT-06:00) Central America
										</option>
										<option
											data-offset="-18000"
											value="Central Time (US &amp; Canada)"
										>
											(GMT-05:00) Central Time (US &amp; Canada)
										</option>
										<option data-offset="-18000" value="Guadalajara">
											(GMT-05:00) Guadalajara
										</option>
										<option data-offset="-18000" value="Mexico City">
											(GMT-05:00) Mexico City
										</option>
										<option data-offset="-18000" value="Monterrey">
											(GMT-05:00) Monterrey
										</option>
										<option data-offset="-18000" value="Bogota">
											(GMT-05:00) Bogota
										</option>
										<option data-offset="-18000" value="Lima">
											(GMT-05:00) Lima
										</option>
										<option data-offset="-18000" value="Quito">
											(GMT-05:00) Quito
										</option>
										<option
											data-offset="-14400"
											value="Eastern Time (US &amp; Canada)"
										>
											(GMT-04:00) Eastern Time (US &amp; Canada)
										</option>
										<option data-offset="-14400" value="Indiana (East)">
											(GMT-04:00) Indiana (East)
										</option>
										<option data-offset="-14400" value="Caracas">
											(GMT-04:00) Caracas
										</option>
										<option data-offset="-14400" value="La Paz">
											(GMT-04:00) La Paz
										</option>
										<option data-offset="-14400" value="Georgetown">
											(GMT-04:00) Georgetown
										</option>
										<option data-offset="-10800" value="Atlantic Time (Canada)">
											(GMT-03:00) Atlantic Time (Canada)
										</option>
										<option data-offset="-10800" value="Santiago">
											(GMT-03:00) Santiago
										</option>
										<option data-offset="-10800" value="Brasilia">
											(GMT-03:00) Brasilia
										</option>
										<option data-offset="-10800" value="Buenos Aires">
											(GMT-03:00) Buenos Aires
										</option>
										<option data-offset="-9000" value="Newfoundland">
											(GMT-02:30) Newfoundland
										</option>
										<option data-offset="-7200" value="Greenland">
											(GMT-02:00) Greenland
										</option>
										<option data-offset="-7200" value="Mid-Atlantic">
											(GMT-02:00) Mid-Atlantic
										</option>
										<option data-offset="-3600" value="Cape Verde Is.">
											(GMT-01:00) Cape Verde Is.
										</option>
										<option data-offset="0" value="Azores">
											(GMT) Azores
										</option>
										<option data-offset="0" value="Monrovia">
											(GMT) Monrovia
										</option>
										<option data-offset="0" value="UTC">
											(GMT) UTC
										</option>
										<option data-offset="3600" value="Dublin">
											(GMT+01:00) Dublin
										</option>
										<option data-offset="3600" value="Edinburgh">
											(GMT+01:00) Edinburgh
										</option>
										<option data-offset="3600" value="Lisbon">
											(GMT+01:00) Lisbon
										</option>
										<option data-offset="3600" value="London">
											(GMT+01:00) London
										</option>
										<option data-offset="3600" value="Casablanca">
											(GMT+01:00) Casablanca
										</option>
										<option data-offset="3600" value="West Central Africa">
											(GMT+01:00) West Central Africa
										</option>
										<option data-offset="7200" value="Belgrade">
											(GMT+02:00) Belgrade
										</option>
										<option data-offset="7200" value="Bratislava">
											(GMT+02:00) Bratislava
										</option>
										<option data-offset="7200" value="Budapest">
											(GMT+02:00) Budapest
										</option>
										<option data-offset="7200" value="Ljubljana">
											(GMT+02:00) Ljubljana
										</option>
										<option data-offset="7200" value="Prague">
											(GMT+02:00) Prague
										</option>
										<option data-offset="7200" value="Sarajevo">
											(GMT+02:00) Sarajevo
										</option>
										<option data-offset="7200" value="Skopje">
											(GMT+02:00) Skopje
										</option>
										<option data-offset="7200" value="Warsaw">
											(GMT+02:00) Warsaw
										</option>
										<option data-offset="7200" value="Zagreb">
											(GMT+02:00) Zagreb
										</option>
										<option data-offset="7200" value="Brussels">
											(GMT+02:00) Brussels
										</option>
										<option data-offset="7200" value="Copenhagen">
											(GMT+02:00) Copenhagen
										</option>
										<option data-offset="7200" value="Madrid">
											(GMT+02:00) Madrid
										</option>
										<option data-offset="7200" value="Paris">
											(GMT+02:00) Paris
										</option>
										<option data-offset="7200" value="Amsterdam">
											(GMT+02:00) Amsterdam
										</option>
										<option data-offset="7200" value="Berlin">
											(GMT+02:00) Berlin
										</option>
										<option data-offset="7200" value="Bern">
											(GMT+02:00) Bern
										</option>
										<option data-offset="7200" value="Rome">
											(GMT+02:00) Rome
										</option>
										<option data-offset="7200" value="Stockholm">
											(GMT+02:00) Stockholm
										</option>
										<option data-offset="7200" value="Vienna">
											(GMT+02:00) Vienna
										</option>
										<option data-offset="7200" value="Cairo">
											(GMT+02:00) Cairo
										</option>
										<option data-offset="7200" value="Harare">
											(GMT+02:00) Harare
										</option>
										<option data-offset="7200" value="Pretoria">
											(GMT+02:00) Pretoria
										</option>
										<option data-offset="10800" value="Bucharest">
											(GMT+03:00) Bucharest
										</option>
										<option data-offset="10800" value="Helsinki">
											(GMT+03:00) Helsinki
										</option>
										<option data-offset="10800" value="Kiev">
											(GMT+03:00) Kiev
										</option>
										<option data-offset="10800" value="Kyiv">
											(GMT+03:00) Kyiv
										</option>
										<option data-offset="10800" value="Riga">
											(GMT+03:00) Riga
										</option>
										<option data-offset="10800" value="Sofia">
											(GMT+03:00) Sofia
										</option>
										<option data-offset="10800" value="Tallinn">
											(GMT+03:00) Tallinn
										</option>
										<option data-offset="10800" value="Vilnius">
											(GMT+03:00) Vilnius
										</option>
										<option data-offset="10800" value="Athens">
											(GMT+03:00) Athens
										</option>
										<option data-offset="10800" value="Istanbul">
											(GMT+03:00) Istanbul
										</option>
										<option data-offset="10800" value="Minsk">
											(GMT+03:00) Minsk
										</option>
										<option data-offset="10800" value="Jerusalem">
											(GMT+03:00) Jerusalem
										</option>
										<option data-offset="10800" value="Moscow">
											(GMT+03:00) Moscow
										</option>
										<option data-offset="10800" value="St. Petersburg">
											(GMT+03:00) St. Petersburg
										</option>
										<option data-offset="10800" value="Volgograd">
											(GMT+03:00) Volgograd
										</option>
										<option data-offset="10800" value="Kuwait">
											(GMT+03:00) Kuwait
										</option>
										<option data-offset="10800" value="Riyadh">
											(GMT+03:00) Riyadh
										</option>
										<option data-offset="10800" value="Nairobi">
											(GMT+03:00) Nairobi
										</option>
										<option data-offset="10800" value="Baghdad">
											(GMT+03:00) Baghdad
										</option>
										<option data-offset="14400" value="Abu Dhabi">
											(GMT+04:00) Abu Dhabi
										</option>
										<option data-offset="14400" value="Muscat">
											(GMT+04:00) Muscat
										</option>
										<option data-offset="14400" value="Baku">
											(GMT+04:00) Baku
										</option>
										<option data-offset="14400" value="Tbilisi">
											(GMT+04:00) Tbilisi
										</option>
										<option data-offset="14400" value="Yerevan">
											(GMT+04:00) Yerevan
										</option>
										<option data-offset="16200" value="Tehran">
											(GMT+04:30) Tehran
										</option>
										<option data-offset="16200" value="Kabul">
											(GMT+04:30) Kabul
										</option>
										<option data-offset="18000" value="Ekaterinburg">
											(GMT+05:00) Ekaterinburg
										</option>
										<option data-offset="18000" value="Islamabad">
											(GMT+05:00) Islamabad
										</option>
										<option data-offset="18000" value="Karachi">
											(GMT+05:00) Karachi
										</option>
										<option data-offset="18000" value="Tashkent">
											(GMT+05:00) Tashkent
										</option>
										<option data-offset="19800" value="Chennai">
											(GMT+05:30) Chennai
										</option>
										<option data-offset="19800" value="Kolkata">
											(GMT+05:30) Kolkata
										</option>
										<option data-offset="19800" value="Mumbai">
											(GMT+05:30) Mumbai
										</option>
										<option data-offset="19800" value="New Delhi">
											(GMT+05:30) New Delhi
										</option>
										<option data-offset="19800" value="Sri Jayawardenepura">
											(GMT+05:30) Sri Jayawardenepura
										</option>
										<option data-offset="20700" value="Kathmandu">
											(GMT+05:45) Kathmandu
										</option>
										<option data-offset="21600" value="Astana">
											(GMT+06:00) Astana
										</option>
										<option data-offset="21600" value="Dhaka">
											(GMT+06:00) Dhaka
										</option>
										<option data-offset="21600" value="Almaty">
											(GMT+06:00) Almaty
										</option>
										<option data-offset="21600" value="Urumqi">
											(GMT+06:00) Urumqi
										</option>
										<option data-offset="23400" value="Rangoon">
											(GMT+06:30) Rangoon
										</option>
										<option data-offset="25200" value="Novosibirsk">
											(GMT+07:00) Novosibirsk
										</option>
										<option data-offset="25200" value="Bangkok">
											(GMT+07:00) Bangkok
										</option>
										<option data-offset="25200" value="Hanoi">
											(GMT+07:00) Hanoi
										</option>
										<option data-offset="25200" value="Jakarta">
											(GMT+07:00) Jakarta
										</option>
										<option data-offset="25200" value="Krasnoyarsk">
											(GMT+07:00) Krasnoyarsk
										</option>
										<option data-offset="28800" value="Beijing">
											(GMT+08:00) Beijing
										</option>
										<option data-offset="28800" value="Chongqing">
											(GMT+08:00) Chongqing
										</option>
										<option data-offset="28800" value="Hong Kong">
											(GMT+08:00) Hong Kong
										</option>
										<option data-offset="28800" value="Kuala Lumpur">
											(GMT+08:00) Kuala Lumpur
										</option>
										<option data-offset="28800" value="Singapore">
											(GMT+08:00) Singapore
										</option>
										<option data-offset="28800" value="Taipei">
											(GMT+08:00) Taipei
										</option>
										<option data-offset="28800" value="Perth">
											(GMT+08:00) Perth
										</option>
										<option data-offset="28800" value="Irkutsk">
											(GMT+08:00) Irkutsk
										</option>
										<option data-offset="28800" value="Ulaan Bataar">
											(GMT+08:00) Ulaan Bataar
										</option>
										<option data-offset="32400" value="Seoul">
											(GMT+09:00) Seoul
										</option>
										<option data-offset="32400" value="Osaka">
											(GMT+09:00) Osaka
										</option>
										<option data-offset="32400" value="Sapporo">
											(GMT+09:00) Sapporo
										</option>
										<option data-offset="32400" value="Tokyo">
											(GMT+09:00) Tokyo
										</option>
										<option data-offset="32400" value="Yakutsk">
											(GMT+09:00) Yakutsk
										</option>
										<option data-offset="34200" value="Darwin">
											(GMT+09:30) Darwin
										</option>
										<option data-offset="34200" value="Adelaide">
											(GMT+09:30) Adelaide
										</option>
										<option data-offset="36000" value="Canberra">
											(GMT+10:00) Canberra
										</option>
										<option data-offset="36000" value="Melbourne">
											(GMT+10:00) Melbourne
										</option>
										<option data-offset="36000" value="Sydney">
											(GMT+10:00) Sydney
										</option>
										<option data-offset="36000" value="Brisbane">
											(GMT+10:00) Brisbane
										</option>
										<option data-offset="36000" value="Hobart">
											(GMT+10:00) Hobart
										</option>
										<option data-offset="36000" value="Vladivostok">
											(GMT+10:00) Vladivostok
										</option>
										<option data-offset="36000" value="Guam">
											(GMT+10:00) Guam
										</option>
										<option data-offset="36000" value="Port Moresby">
											(GMT+10:00) Port Moresby
										</option>
										<option data-offset="36000" value="Solomon Is.">
											(GMT+10:00) Solomon Is.
										</option>
										<option data-offset="39600" value="Magadan">
											(GMT+11:00) Magadan
										</option>
										<option data-offset="39600" value="New Caledonia">
											(GMT+11:00) New Caledonia
										</option>
										<option data-offset="43200" value="Fiji">
											(GMT+12:00) Fiji
										</option>
										<option data-offset="43200" value="Kamchatka">
											(GMT+12:00) Kamchatka
										</option>
										<option data-offset="43200" value="Marshall Is.">
											(GMT+12:00) Marshall Is.
										</option>
										<option data-offset="43200" value="Auckland">
											(GMT+12:00) Auckland
										</option>
										<option data-offset="43200" value="Wellington">
											(GMT+12:00) Wellington
										</option>
										<option data-offset="46800" value="Nuku'alofa">
											(GMT+13:00) Nuku'alofa
										</option>
									</select>
								</div>
							</div>
							<div className="form-group row align-items-center">
								<label className="col-xl-3 col-lg-3 col-form-label">
									Communication
								</label>
								<div className="col-lg-9 col-xl-6">
									<div className="checkbox-inline">
										<label className="checkbox">
											<input name="communication" type="checkbox" />
											<span></span>Email
										</label>
										<label className="checkbox">
											<input name="communication" type="checkbox" />
											<span></span>SMS
										</label>
										<label className="checkbox">
											<input name="communication" type="checkbox" />
											<span></span>Phone
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);

	const renderListsSection = () => (
		<Fragment>
			{(!props.signup || activeStep === "lists") && (
				<div className="pb-5" data-wizard-type="step-content">
					<div className="col-lg-9 col-xl-6 mt-5">
						<h1 className="mb-10 font-weight-bold text-dark">
							Subsribe to Prayer Lists
						</h1>
					</div>
					{userLists && userLists.length > 0 && (
						<div className="form-group mt-5">
							<h4>Your Current Lists</h4>
							{
								<ul>
									{userLists.map((list: any, index: number) => {
										return (
											<li key={index}>
												{list.name}
												<br />
												<small>{list.org?.name}</small>
											</li>
										);
									})}
								</ul>
							}
						</div>
					)}
					<JoinList />
					{/* <div className='form-group'>
                                                        <label>Address Line 1</label>
                                                        <input type='text' className='form-control form-control-lg form-control-solid' name='address1' placeholder='Address Line 1' value='Address Line 1' />
                                                        <span className='form-text text-muted'>Please enter your Address.</span>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label>Address Line 2</label>
                                                        <input type='text' className='form-control form-control-lg form-control-solid' name='address2' placeholder='Address Line 2' value='Address Line 2' />
                                                        <span className='form-text text-muted'>Please enter your Address.</span>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-xl-6'>
                                                            <div className='form-group'>
                                                                <label>Postcode</label>
                                                                <input type='text' className='form-control form-control-lg form-control-solid' name='postcode' placeholder='Postcode' value='3000' />
                                                                <span className='form-text text-muted'>Please enter your Postcode.</span>
                                                            </div>
                                                        </div>
                                                        <div className='col-xl-6'>
                                                            <div className='form-group'>
                                                                <label>City</label>
                                                                <input type='text' className='form-control form-control-lg form-control-solid' name='city' placeholder='City' value='Melbourne' />
                                                                <span className='form-text text-muted'>Please enter your City.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-xl-6'>
                                                            <div className='form-group'>
                                                                <label>State</label>
                                                                <input type='text' className='form-control form-control-lg form-control-solid' name='state' placeholder='State' value='VIC' />
                                                                <span className='form-text text-muted'>Please enter your State.</span>
                                                            </div>
                                                        </div>
                                                        <div className='col-xl-6'>
                                                            <div className='form-group'>
                                                                <label>Country</label>
                                                                <select name='country' className='form-control form-control-lg form-control-solid'>
                                                                    <option value=''>Select</option>
                                                                    <option value='AF'>Afghanistan</option>
                                                                    <option value='AX'>Åland Islands</option>
                                                                    <option value='AL'>Albania</option>
                                                                    <option value='DZ'>Algeria</option>
                                                                    <option value='AS'>American Samoa</option>
                                                                    <option value='AD'>Andorra</option>
                                                                    <option value='AO'>Angola</option>
                                                                    <option value='AI'>Anguilla</option>
                                                                    <option value='AQ'>Antarctica</option>
                                                                    <option value='AG'>Antigua and Barbuda</option>
                                                                    <option value='AR'>Argentina</option>
                                                                    <option value='AM'>Armenia</option>
                                                                    <option value='AW'>Aruba</option>
                                                                    <option value='AU' selected={true}>Australia</option>
                                                                    <option value='AT'>Austria</option>
                                                                    <option value='AZ'>Azerbaijan</option>
                                                                    <option value='BS'>Bahamas</option>
                                                                    <option value='BH'>Bahrain</option>
                                                                    <option value='BD'>Bangladesh</option>
                                                                    <option value='BB'>Barbados</option>
                                                                    <option value='BY'>Belarus</option>
                                                                    <option value='BE'>Belgium</option>
                                                                    <option value='BZ'>Belize</option>
                                                                    <option value='BJ'>Benin</option>
                                                                    <option value='BM'>Bermuda</option>
                                                                    <option value='BT'>Bhutan</option>
                                                                    <option value='BO'>Bolivia, Plurinational State of</option>
                                                                    <option value='BQ'>Bonaire, Sint Eustatius and Saba</option>
                                                                    <option value='BA'>Bosnia and Herzegovina</option>
                                                                    <option value='BW'>Botswana</option>
                                                                    <option value='BV'>Bouvet Island</option>
                                                                    <option value='BR'>Brazil</option>
                                                                    <option value='IO'>British Indian Ocean Territory</option>
                                                                    <option value='BN'>Brunei Darussalam</option>
                                                                    <option value='BG'>Bulgaria</option>
                                                                    <option value='BF'>Burkina Faso</option>
                                                                    <option value='BI'>Burundi</option>
                                                                    <option value='KH'>Cambodia</option>
                                                                    <option value='CM'>Cameroon</option>
                                                                    <option value='CA'>Canada</option>
                                                                    <option value='CV'>Cape Verde</option>
                                                                    <option value='KY'>Cayman Islands</option>
                                                                    <option value='CF'>Central African Republic</option>
                                                                    <option value='TD'>Chad</option>
                                                                    <option value='CL'>Chile</option>
                                                                    <option value='CN'>China</option>
                                                                    <option value='CX'>Christmas Island</option>
                                                                    <option value='CC'>Cocos (Keeling) Islands</option>
                                                                    <option value='CO'>Colombia</option>
                                                                    <option value='KM'>Comoros</option>
                                                                    <option value='CG'>Congo</option>
                                                                    <option value='CD'>Congo, the Democratic Republic of the</option>
                                                                    <option value='CK'>Cook Islands</option>
                                                                    <option value='CR'>Costa Rica</option>
                                                                    <option value='CI'>Côte d'Ivoire</option>
                                                                    <option value='HR'>Croatia</option>
                                                                    <option value='CU'>Cuba</option>
                                                                    <option value='CW'>Curaçao</option>
                                                                    <option value='CY'>Cyprus</option>
                                                                    <option value='CZ'>Czech Republic</option>
                                                                    <option value='DK'>Denmark</option>
                                                                    <option value='DJ'>Djibouti</option>
                                                                    <option value='DM'>Dominica</option>
                                                                    <option value='DO'>Dominican Republic</option>
                                                                    <option value='EC'>Ecuador</option>
                                                                    <option value='EG'>Egypt</option>
                                                                    <option value='SV'>El Salvador</option>
                                                                    <option value='GQ'>Equatorial Guinea</option>
                                                                    <option value='ER'>Eritrea</option>
                                                                    <option value='EE'>Estonia</option>
                                                                    <option value='ET'>Ethiopia</option>
                                                                    <option value='FK'>Falkland Islands (Malvinas)</option>
                                                                    <option value='FO'>Faroe Islands</option>
                                                                    <option value='FJ'>Fiji</option>
                                                                    <option value='FI'>Finland</option>
                                                                    <option value='FR'>France</option>
                                                                    <option value='GF'>French Guiana</option>
                                                                    <option value='PF'>French Polynesia</option>
                                                                    <option value='TF'>French Southern Territories</option>
                                                                    <option value='GA'>Gabon</option>
                                                                    <option value='GM'>Gambia</option>
                                                                    <option value='GE'>Georgia</option>
                                                                    <option value='DE'>Germany</option>
                                                                    <option value='GH'>Ghana</option>
                                                                    <option value='GI'>Gibraltar</option>
                                                                    <option value='GR'>Greece</option>
                                                                    <option value='GL'>Greenland</option>
                                                                    <option value='GD'>Grenada</option>
                                                                    <option value='GP'>Guadeloupe</option>
                                                                    <option value='GU'>Guam</option>
                                                                    <option value='GT'>Guatemala</option>
                                                                    <option value='GG'>Guernsey</option>
                                                                    <option value='GN'>Guinea</option>
                                                                    <option value='GW'>Guinea-Bissau</option>
                                                                    <option value='GY'>Guyana</option>
                                                                    <option value='HT'>Haiti</option>
                                                                    <option value='HM'>Heard Island and McDonald Islands</option>
                                                                    <option value='VA'>Holy See (Vatican City State)</option>
                                                                    <option value='HN'>Honduras</option>
                                                                    <option value='HK'>Hong Kong</option>
                                                                    <option value='HU'>Hungary</option>
                                                                    <option value='IS'>Iceland</option>
                                                                    <option value='IN'>India</option>
                                                                    <option value='ID'>Indonesia</option>
                                                                    <option value='IR'>Iran, Islamic Republic of</option>
                                                                    <option value='IQ'>Iraq</option>
                                                                    <option value='IE'>Ireland</option>
                                                                    <option value='IM'>Isle of Man</option>
                                                                    <option value='IL'>Israel</option>
                                                                    <option value='IT'>Italy</option>
                                                                    <option value='JM'>Jamaica</option>
                                                                    <option value='JP'>Japan</option>
                                                                    <option value='JE'>Jersey</option>
                                                                    <option value='JO'>Jordan</option>
                                                                    <option value='KZ'>Kazakhstan</option>
                                                                    <option value='KE'>Kenya</option>
                                                                    <option value='KI'>Kiribati</option>
                                                                    <option value='KP'>Korea, Democratic People's Republic of</option>
                                                                    <option value='KR'>Korea, Republic of</option>
                                                                    <option value='KW'>Kuwait</option>
                                                                    <option value='KG'>Kyrgyzstan</option>
                                                                    <option value='LA'>Lao People's Democratic Republic</option>
                                                                    <option value='LV'>Latvia</option>
                                                                    <option value='LB'>Lebanon</option>
                                                                    <option value='LS'>Lesotho</option>
                                                                    <option value='LR'>Liberia</option>
                                                                    <option value='LY'>Libya</option>
                                                                    <option value='LI'>Liechtenstein</option>
                                                                    <option value='LT'>Lithuania</option>
                                                                    <option value='LU'>Luxembourg</option>
                                                                    <option value='MO'>Macao</option>
                                                                    <option value='MK'>Macedonia, the former Yugoslav Republic of</option>
                                                                    <option value='MG'>Madagascar</option>
                                                                    <option value='MW'>Malawi</option>
                                                                    <option value='MY'>Malaysia</option>
                                                                    <option value='MV'>Maldives</option>
                                                                    <option value='ML'>Mali</option>
                                                                    <option value='MT'>Malta</option>
                                                                    <option value='MH'>Marshall Islands</option>
                                                                    <option value='MQ'>Martinique</option>
                                                                    <option value='MR'>Mauritania</option>
                                                                    <option value='MU'>Mauritius</option>
                                                                    <option value='YT'>Mayotte</option>
                                                                    <option value='MX'>Mexico</option>
                                                                    <option value='FM'>Micronesia, Federated States of</option>
                                                                    <option value='MD'>Moldova, Republic of</option>
                                                                    <option value='MC'>Monaco</option>
                                                                    <option value='MN'>Mongolia</option>
                                                                    <option value='ME'>Montenegro</option>
                                                                    <option value='MS'>Montserrat</option>
                                                                    <option value='MA'>Morocco</option>
                                                                    <option value='MZ'>Mozambique</option>
                                                                    <option value='MM'>Myanmar</option>
                                                                    <option value='NA'>Namibia</option>
                                                                    <option value='NR'>Nauru</option>
                                                                    <option value='NP'>Nepal</option>
                                                                    <option value='NL'>Netherlands</option>
                                                                    <option value='NC'>New Caledonia</option>
                                                                    <option value='NZ'>New Zealand</option>
                                                                    <option value='NI'>Nicaragua</option>
                                                                    <option value='NE'>Niger</option>
                                                                    <option value='NG'>Nigeria</option>
                                                                    <option value='NU'>Niue</option>
                                                                    <option value='NF'>Norfolk Island</option>
                                                                    <option value='MP'>Northern Mariana Islands</option>
                                                                    <option value='NO'>Norway</option>
                                                                    <option value='OM'>Oman</option>
                                                                    <option value='PK'>Pakistan</option>
                                                                    <option value='PW'>Palau</option>
                                                                    <option value='PS'>Palestinian Territory, Occupied</option>
                                                                    <option value='PA'>Panama</option>
                                                                    <option value='PG'>Papua New Guinea</option>
                                                                    <option value='PY'>Paraguay</option>
                                                                    <option value='PE'>Peru</option>
                                                                    <option value='PH'>Philippines</option>
                                                                    <option value='PN'>Pitcairn</option>
                                                                    <option value='PL'>Poland</option>
                                                                    <option value='PT'>Portugal</option>
                                                                    <option value='PR'>Puerto Rico</option>
                                                                    <option value='QA'>Qatar</option>
                                                                    <option value='RE'>Réunion</option>
                                                                    <option value='RO'>Romania</option>
                                                                    <option value='RU'>Russian Federation</option>
                                                                    <option value='RW'>Rwanda</option>
                                                                    <option value='BL'>Saint Barthélemy</option>
                                                                    <option value='SH'>Saint Helena, Ascension and Tristan da Cunha</option>
                                                                    <option value='KN'>Saint Kitts and Nevis</option>
                                                                    <option value='LC'>Saint Lucia</option>
                                                                    <option value='MF'>Saint Martin (French part)</option>
                                                                    <option value='PM'>Saint Pierre and Miquelon</option>
                                                                    <option value='VC'>Saint Vincent and the Grenadines</option>
                                                                    <option value='WS'>Samoa</option>
                                                                    <option value='SM'>San Marino</option>
                                                                    <option value='ST'>Sao Tome and Principe</option>
                                                                    <option value='SA'>Saudi Arabia</option>
                                                                    <option value='SN'>Senegal</option>
                                                                    <option value='RS'>Serbia</option>
                                                                    <option value='SC'>Seychelles</option>
                                                                    <option value='SL'>Sierra Leone</option>
                                                                    <option value='SG'>Singapore</option>
                                                                    <option value='SX'>Sint Maarten (Dutch part)</option>
                                                                    <option value='SK'>Slovakia</option>
                                                                    <option value='SI'>Slovenia</option>
                                                                    <option value='SB'>Solomon Islands</option>
                                                                    <option value='SO'>Somalia</option>
                                                                    <option value='ZA'>South Africa</option>
                                                                    <option value='GS'>South Georgia and the South Sandwich Islands</option>
                                                                    <option value='SS'>South Sudan</option>
                                                                    <option value='ES'>Spain</option>
                                                                    <option value='LK'>Sri Lanka</option>
                                                                    <option value='SD'>Sudan</option>
                                                                    <option value='SR'>Suriname</option>
                                                                    <option value='SJ'>Svalbard and Jan Mayen</option>
                                                                    <option value='SZ'>Swaziland</option>
                                                                    <option value='SE'>Sweden</option>
                                                                    <option value='CH'>Switzerland</option>
                                                                    <option value='SY'>Syrian Arab Republic</option>
                                                                    <option value='TW'>Taiwan, Province of China</option>
                                                                    <option value='TJ'>Tajikistan</option>
                                                                    <option value='TZ'>Tanzania, United Republic of</option>
                                                                    <option value='TH'>Thailand</option>
                                                                    <option value='TL'>Timor-Leste</option>
                                                                    <option value='TG'>Togo</option>
                                                                    <option value='TK'>Tokelau</option>
                                                                    <option value='TO'>Tonga</option>
                                                                    <option value='TT'>Trinidad and Tobago</option>
                                                                    <option value='TN'>Tunisia</option>
                                                                    <option value='TR'>Turkey</option>
                                                                    <option value='TM'>Turkmenistan</option>
                                                                    <option value='TC'>Turks and Caicos Islands</option>
                                                                    <option value='TV'>Tuvalu</option>
                                                                    <option value='UG'>Uganda</option>
                                                                    <option value='UA'>Ukraine</option>
                                                                    <option value='AE'>United Arab Emirates</option>
                                                                    <option value='GB'>United Kingdom</option>
                                                                    <option value='US'>United States</option>
                                                                    <option value='UM'>United States Minor Outlying Islands</option>
                                                                    <option value='UY'>Uruguay</option>
                                                                    <option value='UZ'>Uzbekistan</option>
                                                                    <option value='VU'>Vanuatu</option>
                                                                    <option value='VE'>Venezuela, Bolivarian Republic of</option>
                                                                    <option value='VN'>Viet Nam</option>
                                                                    <option value='VG'>Virgin Islands, British</option>
                                                                    <option value='VI'>Virgin Islands, U.S.</option>
                                                                    <option value='WF'>Wallis and Futuna</option>
                                                                    <option value='EH'>Western Sahara</option>
                                                                    <option value='YE'>Yemen</option>
                                                                    <option value='ZM'>Zambia</option>
                                                                    <option value='ZW'>Zimbabwe</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div> */}
				</div>
			)}
		</Fragment>
	);

	const renderReviewSection = () => (
		<Fragment>
			{(!props.signup || activeStep === "review") && (
				<div className="pb-5" data-wizard-type="step-content">
					<div className="col-lg-9 col-xl-6 mt-5">
						<h1 className="mb-10 font-weight-bold text-dark">Ready to Pray</h1>
					</div>
					<span>
						You are now ready to begin praying using the Prayer app.
						<br />
						<br />
						Click "Get Started" below to see your prayers.
					</span>
				</div>
			)}
		</Fragment>
	);

	const renderInWizard = () => (
		<div
			className="wizard wizard-1"
			id="kt_contact_add"
			data-wizard-state="step-first"
			data-wizard-clickable="false"
		>
			<div className="wizard-nav border-bottom">
				<div className="wizard-steps p-8 p-lg-10">{renderWizardSteps()}</div>
			</div>
			<Row className="text-left justify-content-center my-10 px-8 my-lg-15 px-lg-10">
				<Col className="col-xl-12 col-xxl-7">
					<Form id="kt_contact_add_form">
						{renderInfoSection()}
						{renderSettingsSection()}
						{renderListsSection()}
						{renderReviewSection()}
						<div className="d-flex justify-content-between border-top pt-10">
							<div className="mr-2">
								{activeStep !== "info" && (
									<button
										onClick={() => onPrev()}
										type="button"
										className="bg-white text-center text-black font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
									>
										<i className="fa fa-sm fa-chevron-left"></i> Previous
									</button>
								)}
							</div>
							<div>
								{activeStep !== "review" ? (
									<button
										onClick={() => onNext()}
										type="button"
										className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
									>
										Next <i className="fa fa-sm fa-chevron-right"></i>
									</button>
								) : (
									<button
										onClick={() => onSave()}
										type="button"
										className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
									>
										Get Started
									</button>
								)}
							</div>
						</div>
					</Form>
				</Col>
			</Row>
		</div>
	);

	const renderInSections = () => (
		<Card className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
			<Card.Body className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
				<Link to="/settings" className="d-inline-flex mt-2 pointer">
					<i className="ti-arrow-left font-sm text-white"></i>
					<h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-0">
						User Information
					</h4>
				</Link>
			</Card.Body>
			<Card.Body>
				<Row className="text-left justify-content-center my-10 px-8 my-lg-15 px-lg-10">
					<Col className="col-xl-12 col-xxl-7">
						<Form id="kt_contact_add_form">
							{renderInfoSection()}
							{renderSettingsSection()}
							<div className="d-flex justify-content-between border-top pt-10">
								<div className="mr-2">
									<Button
										onClick={() => onCancel()}
										className="bg-white text-center text-grey font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
									>
										Cancel
									</Button>
								</div>
								<div>
									<Button
										onClick={() => onSave()}
										className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
									>
										Save
									</Button>
								</div>
							</div>
						</Form>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);

	useEffect(() => {
		if (userProfile) {
			setFirstName(userProfile?.firstName);
			setLastName(userProfile?.lastName);
		}
	}, [userProfile, publicLists]);

	return (
		<Fragment>{props.signup ? renderInWizard() : renderInSections()}</Fragment>
	);
};

export default UserProfile;

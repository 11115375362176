import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Card,
	Col,
	Container,
	Dropdown,
	Form,
	Modal,
	ModalBody,
	ModalFooter,
	OverlayTrigger,
	Row,
	Tooltip,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { RootState } from "../../../store";
import JoinList from "./JoinList";
import { unsubscribeFromList } from "../../../services/apiService";
import {
	fetchUserComments,
	fetchUserLists,
	fetchUserPosts,
} from "../../../store/User/UserSlice";

import AppEmitter from "../../../services/appEmitter";
import PageTitle from "../../shared/components/Pagetitle";
import NewListButton from "./NewListButton";

const DisplayLists = ({
	title = "My Prayer Lists",
	rootUrl = "lists",
	showLists = true,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { userProfile, userLists } = useSelector(
		(state: RootState) => state.user
	);

	const [listId, setListId] = useState("");
	const [listName, setListName] = useState("");

	const [unsubModal, setUnSubModal] = useState(false);

	const onUnsubscribe = (listId: string) => {
		unsubscribeFromList(userProfile.userDataId, listId).then((res) => {
			dispatch(fetchUserPosts(userProfile.userDataId));
			dispatch(fetchUserComments(userProfile.userDataId));
			dispatch(fetchUserLists(userProfile.userDataId));
		});
	};

	const toggleUnSub = () => {
		setUnSubModal(!unsubModal);
		// onUnsubscribe(list.id)
	};

	const toggleAdd = () => {
		setUnSubModal(!unsubModal);
		// onUnsubscribe(list.id)
	};

	const onCancel = () => {
		setUnSubModal(false);
	};

	const onConfirm = () => {
		setUnSubModal(false);
		onUnsubscribe(listId);
	};

	const onManageList = (listId: string) => {
		AppEmitter.emit("SHOW_MOBILE_MENU", false);
		history.push(`/my-lists/manage/${listId}`);
	};

	return (
		<Fragment>
			<Modal show={unsubModal} toggle={toggleUnSub} className="">
				<Modal.Header>
					<span className="text-dark-75 font-size-lg text-hover-primary font-weight-bolder">
						Unsubscribe from this list?
					</span>
				</Modal.Header>
				<ModalBody>
					<div className="mb-3">
						Are you sure you want to unsubscribe from the list {listName}?
					</div>
					<div>
						This may remove some prayers from your feed and you'll have to
						re-subscribe to view them again..
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						className="bg-white text-center text-gray font-xsss fw-600 p-2 rounded-3 d-inline-block"
						onClick={onCancel}
					>
						Cancel
					</Button>
					<Button
						className="bg-red text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block"
						onClick={onConfirm}
					>
						Unsubscribe
					</Button>
				</ModalFooter>
			</Modal>
			<PageTitle title={title} dropdown={<NewListButton />} />
			<div className="d-flex flex-column-fluid mt-2">
				{showLists && userLists && userLists.length > 0 && (
					<Row>
						{userLists.map((list: any, index: number) => {
							return (
								<Col xs={12}>
									<Card
										className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"
										key={index}
									>
										<Card.Body className="d-flex">
											<div className="">
												<h3>
													<Link to={`/${rootUrl}/${list.id}`}>{list.name}</Link>
												</h3>
												<p>{list.description}</p>
											</div>
											<div className="d-flex align-items-start fw-600 text-grey-900 text-dark lh-26 font-xssss ms-auto">
												<Dropdown drop="down">
													<Dropdown.Toggle
														variant="light-primary"
														id="dropdown-basic"
													>
														<i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
													</Dropdown.Toggle>
													<Dropdown.Menu className="dropdown-menu dropdown-menu-bottom p-2 m-0 rounded-xxl">
														{(list.isModerator || list.isAdministrator) && (
															<Dropdown.Item className="mb-2">
																<Link
																	to="#"
																	onClick={() => onManageList(list.id)}
																	title="Manage List"
																>
																	<i className="fa-xl fas fa-list text-primary bg-white me-1 btn-round-xs" />
																	&nbsp;Manage List
																</Link>
															</Dropdown.Item>
														)}
														<Dropdown.Item className="mb-2">
															<Link to="#" title="Share">
																<i className="fa-xl fas fa-share-square text-primary bg-white me-1 btn-round-xs" />
																&nbsp;Share List
															</Link>
														</Dropdown.Item>
														{
															// Can't unsubscribe from your own list
															list.userId !== userProfile.userDataId && (
																<Dropdown.Item className="mb-2">
																	<OverlayTrigger
																		placement={"bottom"}
																		overlay={
																			<Tooltip id={`tooltip-left`}>
																				Unsubscribe From List
																			</Tooltip>
																		}
																	>
																		<Link
																			to="#"
																			onClick={() => {
																				toggleUnSub();
																				setListId(list.id);
																				setListName(list.name);
																			}}
																		>
																			<i className="fa-xl fas fa-minus-square text-primary bg-white me-1 btn-round-xs" />
																			&nbsp;Unsubscribe
																		</Link>
																	</OverlayTrigger>
																</Dropdown.Item>
															)
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</Card.Body>
									</Card>
								</Col>
							);
						})}
					</Row>
				)}
			</div>

			<div className="d-flex flex-column-fluid mt-2">
				<Row>
					<Col xs={12}>
						<JoinList />
					</Col>
				</Row>
			</div>
		</Fragment>
	);
};

export default DisplayLists;

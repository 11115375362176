import { Provider } from "react-redux";

import { store } from "./store";
import Layout from "./pages/Layout";

import "./App.scss";

function App() {
	return (
		<Provider store={store}>
			<Layout />
		</Provider>
	);
}

export default App;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData } from "../../@types/user.d";
import { Post } from "../../@types/post.d";
import { UserList } from "../../@types/userList.d";
import {
	getUser,
	getUserPosts,
	getUserComments,
	getUserLists,
} from "../../services/apiService";

export const fetchUser = createAsyncThunk(
	"user.fetch",
	async (externalUserId: string) => {
		try {
			return getUser(externalUserId);
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

export const fetchUserPosts = createAsyncThunk(
	"userPosts.fetch",
	async (userId: string) => {
		try {
			return getUserPosts(userId);
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

export const fetchUserComments = createAsyncThunk(
	"userComments.fetch",
	async (userId: string) => {
		try {
			return getUserComments(userId);
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

export const fetchUserLists = createAsyncThunk(
	"userList.fetch",
	async (userId: string) => {
		try {
			return getUserLists(userId);
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

const UserSlice = createSlice({
	name: "UserSlice",
	initialState: {
		userProfile: {} as UserData,
		userPosts: [] as Array<Post>,
		userComments: [] as Array<Post>,
		userLists: [] as Array<UserList>,
		profileStatus: "Unknown",
		currentIndex: 0,
		numPosts: 0,
		loading: false,
	},
	reducers: {
		setProfileStatus(state: any, action: PayloadAction<string>) {
			state.profileStatus = action.payload;
		},
		setUserProfile(state: any, action: PayloadAction<string>) {
			state.userProfile = JSON.parse(action.payload) as UserData;
		},
		setUserPosts(state: any, action: PayloadAction<string>) {
			state.userPosts = JSON.parse(action.payload) as Array<Post>;
		},
		setUserComments(state: any, action: PayloadAction<string>) {
			state.userComments = JSON.parse(action.payload) as Array<Post>;
		},
		setUserLists(state: any, action: PayloadAction<string>) {
			state.userLists = JSON.parse(action.payload) as Array<UserList>;
		},
		setCurrentIndex(state: any, action: PayloadAction<number>) {
			if ((action.payload as number) < 0) {
				state.currentIndex = 0;
				return;
			}
			state.currentIndex = action.payload;
		},
		setNumPosts(state: any, action: PayloadAction<number>) {
			state.numPosts = action.payload;
		},
	},
	extraReducers: (builder) => {
		// User profile Get API Status Reducers
		builder.addCase(fetchUser.pending, (state: { loading: boolean }) => {
			state.loading = true;
		});

		builder.addCase(fetchUser.fulfilled, (state: any, action: any) => {
			state.userProfile = action.payload as UserData;
			state.loading = false;
		});

		builder.addCase(fetchUser.rejected, (state: any, action: any) => {
			console.log(
				`Failed to fetch User Profile HTTP Status Code: ${action.payload}`
			);
			state.loading = false;
		});

		// User posts Get API Status Reducers
		builder.addCase(fetchUserPosts.pending, (state: { loading: boolean }) => {
			state.loading = true;
		});

		builder.addCase(fetchUserPosts.fulfilled, (state: any, action: any) => {
			state.userPosts = action.payload as Array<Post>;
			state.loading = false;
		});

		builder.addCase(fetchUserPosts.rejected, (state: any, action: any) => {
			console.log(
				`Failed to fetch User Posts HTTP Status Code: ${action.payload}`
			);
			state.loading = false;
		});

		// User comments Get API Status Reducers
		builder.addCase(
			fetchUserComments.pending,
			(state: { loading: boolean }) => {
				state.loading = true;
			}
		);

		builder.addCase(fetchUserComments.fulfilled, (state: any, action: any) => {
			state.userComments = action.payload as Array<Post>;
			state.loading = false;
		});

		builder.addCase(fetchUserComments.rejected, (state: any, action: any) => {
			console.log(
				`Failed to fetch User Comments HTTP Status Code: ${action.payload}`
			);
			state.loading = false;
		});

		// User lists Get API Status Reducers
		builder.addCase(fetchUserLists.pending, (state: { loading: boolean }) => {
			state.loading = true;
		});

		builder.addCase(fetchUserLists.fulfilled, (state: any, action: any) => {
			state.userLists = action.payload as Array<UserList>;
			state.loading = false;
		});

		builder.addCase(fetchUserLists.rejected, (state: any, action: any) => {
			console.log(
				`Failed to fetch User Profile HTTP Status Code: ${action.payload}`
			);
			state.loading = false;
		});
	},
});

export const {
	setProfileStatus,
	setUserProfile,
	setUserPosts,
	setUserLists,
	setCurrentIndex,
	setNumPosts,
} = UserSlice.actions;

export default UserSlice.reducer;

import { Fragment } from "react";

import { UserProfile } from "..";

const SignUp = () => {
	return (
		<Fragment>
			<UserProfile signup={true} />
		</Fragment>
	);
};

export default SignUp;

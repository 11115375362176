import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { Post } from "../../@types/post.d";
import { DisplayPost } from "..";
import { sortPostsDesc } from "../../services/apiService";
import { Card, Col, Row } from "react-bootstrap";
import PageTitle from "../shared/components/Pagetitle";
import { CreatePost } from "./components/Createpost";
import { Load } from "../shared/components/Load";

const MyPosts = (post: any, index: number) => {
	const { userProfile, userPosts } = useSelector(
		(state: RootState) => state.user
	);
	const [displayTitle, setDisplayTitle] = useState("My Prayers & Responses");
	const [displayPosts, setDisplayPosts] = useState([] as Array<Post>);

	useEffect(() => {
		let posts: Array<Post> = userPosts?.filter((x: any) => {
			return x.ownerId === userProfile.userDataId && x.parentPostId === null;
		});
		setDisplayPosts(sortPostsDesc(posts));
	}, [userPosts]);

	return (
		<Fragment>
			<PageTitle title={displayTitle} />
			<Row>
				<Col>
					<div id="prayer-panel">
						<CreatePost />
						{displayPosts && displayPosts.length > 0 ? (
							<Fragment>
								{displayPosts.map((post, index) => {
									return (
										<Fragment key={index}>
											<DisplayPost
												post={post}
												index={index}
												allowComments={true}
												allowEdit={userProfile.userDataId === post.ownerId}
												allowManage={userProfile.userDataId === post.ownerId}
												trackViews={true}
											/>
										</Fragment>
									);
								})}
								<Load />
							</Fragment>
						) : (
							<Card className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
								<Card.Body>
									<div className="d-flex flex-column flex-grow-1">
										<span className="text-dark-75 mb-1 font-size-lg font-weight-bolder">
											No Posts to display
										</span>
									</div>
								</Card.Body>
							</Card>
						)}
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};

export default MyPosts;

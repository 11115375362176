import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RootState } from "../../../store";

const DisplayListTiles = ({ title = "My Prayer Lists", rootUrl = "lists" }) => {
	const { userLists } = useSelector((state: RootState) => state.user);
	const [colSize, setColSize] = useState(1);

	useEffect(() => {
		switch (userLists?.length) {
			case 1:
				setColSize(12);
				break;
			case 2:
				setColSize(6);
				break;
			case 3:
				setColSize(4);
				break;
			case 4:
				setColSize(6);
				break;
			case 5:
			default:
				setColSize(4);
				break;
		}
	}, [userLists]);

	return (
		<Fragment>
			<Row>
				{userLists.map((list: any, index: number) => {
					return (
						<Col sm={colSize} key={index} className="pe-2 ps-2">
							<Card className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
								<Card.Body className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
									<i className="text-primary fa-3x fas fa-church"></i>
									<div className="clearfix w-100"></div>
									<h4 className="fw-700 font-xsss mt-3 mb-0">{list.name}</h4>
									<p className="fw-500 font-xssss text-grey-500 mt-0 mb-3"></p>
									<Link
										to={`/${rootUrl}/${list.id}`}
										className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-blue-gradiant font-xssss fw-700 ls-lg text-white"
									>
										View List
									</Link>
								</Card.Body>
							</Card>
						</Col>
					);
				})}
			</Row>
		</Fragment>
	);
};

export default DisplayListTiles;

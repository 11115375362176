import { Route } from "react-router-dom";

import EditList from "../lists/components/EditList";

import "react-datepicker/dist/react-datepicker.css";

const EditPrayer = (props: any) => {
	return (
		<Route>
			<Route path="/edit-list/:postId" children={<EditList />} />
			<Route exact path="/new-list" children={<EditList />} />
		</Route>
	);
};

export default EditPrayer;

import { Fragment, useEffect } from "react";

import "./Spinner.scss";

type SpinnerParams = {
	height?: number;
	width?: number;
};

export const Spinner: React.FC<SpinnerParams> = ({ height, width }) => {
	useEffect(() => {}, []);

	return (
		<Fragment>
			<div className="spinner-conainer">
				<img
					src="/assets/logo/spinner.gif"
					className="spinner"
					alt="Please wait"
				/>
			</div>
		</Fragment>
	);
};

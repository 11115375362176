import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Route, useRouteMatch } from "react-router-dom";

import { RootState } from "../../store";

import { Spinner, DisplayPosts } from "..";

const Posts = (props: any) => {
	let match = useRouteMatch();
	const { userProfile } = useSelector((state: RootState) => state.user);

	return (
		<Fragment>
			{userProfile ? (
				<Fragment>
					<Route
						path="/all-prayers/:listId"
						children={<DisplayPosts userId={userProfile.userDataId} />}
					/>
					<Route
						exact
						path="/all-prayers"
						children={<DisplayPosts userId={userProfile.userDataId} />}
					/>
				</Fragment>
			) : (
				<Spinner />
			)}
		</Fragment>
	);
};

export default Posts;

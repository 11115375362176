import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { RootState } from "../../store";

import { setInvitationCode } from "../../store/Navigation/NavSlice";

export const JoinInitList: React.FC = () => {
	const dispatch = useDispatch();

	const search = useLocation().search;
	const inviteCode = new URLSearchParams(search).get("invite-code");

	const { invitationCode } = useSelector((state: RootState) => state.nav);

	useEffect(() => {
		if (inviteCode) {
			dispatch(setInvitationCode(inviteCode));
		}
	}, [inviteCode]);

	useEffect(() => {
		if (invitationCode && window.location.href.indexOf("my-lists") < 0) {
			window.location.href = `/my-lists?invite-code=${invitationCode}`;
		}
	}, [invitationCode]);

	return <Fragment></Fragment>;
};

import { Fragment } from "react";
import { Row, Col, Container } from "react-bootstrap";

const Team = () => {
	return (
		<Fragment>
			<Row>
				<Col lg="4" md="6" sm="12">
					<div className="card card-custom gutter-b card-stretch">
						<div className="card-body text-center pt-4">
							<div className="d-flex justify-content-end">
								<div
									className="dropdown dropdown-inline"
									data-toggle="tooltip"
									title="Quick actions"
									data-placement="left"
								>
									<a
										href="#"
										className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<i className="ki ki-bold-more-hor"></i>
									</a>
								</div>
							</div>
							<div className="mt-7">
								<div className="symbol symbol-circle symbol-lg-75">
									<img src="assets/media/users/300_14.jpg" alt="image" />
								</div>
								<div className="symbol symbol-lg-75 symbol-circle symbol-primary d-none">
									<span className="font-size-h3 font-weight-boldest">JB</span>
								</div>
							</div>
							<div className="my-2">
								<a
									href="#"
									className="text-dark font-weight-bold text-hover-primary font-size-h4"
								>
									John Beans
								</a>
							</div>
							<span className="label label-inline label-lg label-light-warning btn-sm font-weight-bold">
								Active
							</span>
							<div className="mt-9 mb-6">
								<a
									href="#"
									className="btn btn-md btn-icon btn-light-facebook btn-pill mx-2"
								>
									<i className="socicon-facebook"></i>
								</a>
								<a
									href="#"
									className="btn btn-md btn-icon btn-light-twitter btn-pill mx-2"
								>
									<i className="socicon-twitter"></i>
								</a>
								<a
									href="#"
									className="btn btn-md btn-icon btn-light-linkedin btn-pill mx-2"
								>
									<i className="socicon-linkedin"></i>
								</a>
							</div>
						</div>
					</div>
				</Col>
				<Col xl="3" lg="4" md="6" sm="12">
					<div className="card card-custom gutter-b card-stretch">
						<div className="card-body text-center pt-4">
							<div className="d-flex justify-content-end">
								<div
									className="dropdown dropdown-inline"
									data-toggle="tooltip"
									title="Quick actions"
									data-placement="left"
								>
									<a
										href="#"
										className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<i className="ki ki-bold-more-hor"></i>
									</a>
								</div>
							</div>
							<div className="mt-7">
								<div className="symbol symbol-circle symbol-lg-75">
									<img src="assets/media/users/300_14.jpg" alt="image" />
								</div>
								<div className="symbol symbol-lg-75 symbol-circle symbol-primary d-none">
									<span className="font-size-h3 font-weight-boldest">JB</span>
								</div>
							</div>
							<div className="my-2">
								<a
									href="#"
									className="text-dark font-weight-bold text-hover-primary font-size-h4"
								>
									John Beans
								</a>
							</div>
							<span className="label label-inline label-lg label-light-warning btn-sm font-weight-bold">
								Active
							</span>
							<div className="mt-9 mb-6">
								<a
									href="#"
									className="btn btn-md btn-icon btn-light-facebook btn-pill mx-2"
								>
									<i className="socicon-facebook"></i>
								</a>
								<a
									href="#"
									className="btn btn-md btn-icon btn-light-twitter btn-pill mx-2"
								>
									<i className="socicon-twitter"></i>
								</a>
								<a
									href="#"
									className="btn btn-md btn-icon btn-light-linkedin btn-pill mx-2"
								>
									<i className="socicon-linkedin"></i>
								</a>
							</div>
						</div>
					</div>
				</Col>
				<Col lg="4" md="6" sm="12">
					<div className="card card-custom gutter-b card-stretch">
						<div className="card-body text-center pt-4">
							<div className="d-flex justify-content-end">
								<div
									className="dropdown dropdown-inline"
									data-toggle="tooltip"
									title="Quick actions"
									data-placement="left"
								>
									<a
										href="#"
										className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<i className="ki ki-bold-more-hor"></i>
									</a>
								</div>
							</div>
							<div className="mt-7">
								<div className="symbol symbol-circle symbol-lg-75">
									<img src="assets/media/users/300_14.jpg" alt="image" />
								</div>
								<div className="symbol symbol-lg-75 symbol-circle symbol-primary d-none">
									<span className="font-size-h3 font-weight-boldest">JB</span>
								</div>
							</div>
							<div className="my-2">
								<a
									href="#"
									className="text-dark font-weight-bold text-hover-primary font-size-h4"
								>
									John Beans
								</a>
							</div>
							<span className="label label-inline label-lg label-light-warning btn-sm font-weight-bold">
								Active
							</span>
							<div className="mt-9 mb-6">
								<a
									href="#"
									className="btn btn-md btn-icon btn-light-facebook btn-pill mx-2"
								>
									<i className="socicon-facebook"></i>
								</a>
								<a
									href="#"
									className="btn btn-md btn-icon btn-light-twitter btn-pill mx-2"
								>
									<i className="socicon-twitter"></i>
								</a>
								<a
									href="#"
									className="btn btn-md btn-icon btn-light-linkedin btn-pill mx-2"
								>
									<i className="socicon-linkedin"></i>
								</a>
							</div>
						</div>
					</div>
				</Col>
				<Col lg="4" md="6" sm="12">
					<div className="card card-custom gutter-b card-stretch">
						<div className="card-body text-center pt-4">
							<div className="d-flex justify-content-end">
								<div
									className="dropdown dropdown-inline"
									data-toggle="tooltip"
									title="Quick actions"
									data-placement="left"
								>
									<a
										href="#"
										className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<i className="ki ki-bold-more-hor"></i>
									</a>
								</div>
							</div>
							<div className="mt-7">
								<div className="symbol symbol-circle symbol-lg-75">
									<img src="assets/media/users/300_14.jpg" alt="image" />
								</div>
								<div className="symbol symbol-lg-75 symbol-circle symbol-primary d-none">
									<span className="font-size-h3 font-weight-boldest">JB</span>
								</div>
							</div>
							<div className="my-2">
								<a
									href="#"
									className="text-dark font-weight-bold text-hover-primary font-size-h4"
								>
									John Beans
								</a>
							</div>
							<span className="label label-inline label-lg label-light-warning btn-sm font-weight-bold">
								Active
							</span>
							<div className="mt-9 mb-6">
								<a
									href="#"
									className="btn btn-md btn-icon btn-light-facebook btn-pill mx-2"
								>
									<i className="socicon-facebook"></i>
								</a>
								<a
									href="#"
									className="btn btn-md btn-icon btn-light-twitter btn-pill mx-2"
								>
									<i className="socicon-twitter"></i>
								</a>
								<a
									href="#"
									className="btn btn-md btn-icon btn-light-linkedin btn-pill mx-2"
								>
									<i className="socicon-linkedin"></i>
								</a>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};

export default Team;

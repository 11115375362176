import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import Select, { components } from "react-select";
import { Route, useHistory, useParams } from "react-router-dom";
import {
	SortableContainer,
	SortableElement,
	SortableHandle,
} from "react-sortable-hoc";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { Editor } from "@tinymce/tinymce-react";

import { UserList } from "../../../@types/userList.d";

import { RootState } from "../../../store";

import {
	fetchUserComments,
	fetchUserPosts,
} from "../../../store/User/UserSlice";
import { saveUserPost } from "../../../services/apiService";
import PageTitle from "../../shared/components/Pagetitle";

const SortableMultiValue = SortableElement((props: any) => {
	// this prevents the menu from being opened/closed when the user clicks
	// on a value to begin dragging it. ideally, detecting a click (instead of
	// a drag) would still focus the control and toggle the menu, but that
	// requires some magic with refs that are out of scope for this example
	const onMouseDown = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};
	const innerProps = { ...props.innerProps, onMouseDown };
	return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle((props: any) => (
	<components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Select);

type PostParams = {
	postId: string;
};

const EditPost = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { postId } = useParams<PostParams>();

	const { userProfile, userPosts, userLists } = useSelector(
		(state: RootState) => state.user
	);
	const [sharableLists, setSharableLists] = useState([] as Array<UserList>);
	const [currentPrayer, setCurrentPrayer] = useState<any | null>(null);
	const [prayerTitle, setPrayerTitle] = useState<string>("");
	const [prayerContent, setPrayerContent] = useState<string>("");
	const [selectedLists, setSelectedLists] = useState([]);
	const [startDate, setStartDate] = useState<Date | null>(null);

	const today = new Date();

	useEffect(() => {
		if (userPosts.length > 0) {
			if (postId && postId !== "") {
				var matches = userPosts.filter((p: any) => {
					return p.postId === postId;
				});

				if (!matches || matches.length === 0) {
					//history.push('/new-prayer');
				}

				let post = matches[0];
				setCurrentPrayer(post);
				setPrayerTitle(post.title as string);
				setPrayerContent(post.content as string);

				let lists = post.postLists.map((item, index) => {
					debugger;
					let match = userLists.find(
						(ul) => ul.id === item.listId && ul.canPost
					);
					if (match) {
						return {
							label: item.listName,
							value: item.listId,
						};
					}
					return null;
				});
				setSelectedLists(lists as []);
			}
		}

		if (userLists) {
			debugger;
			setSharableLists(userLists.filter((x: any) => x.canPost || x.canManage));
		}
	}, [userPosts, userLists, postId]);

	const handleEditorChange = (e: any) => {
		setPrayerContent(e.target.getContent());
	};

	const onChange = (selectedOptions: any) => {
		setSelectedLists(selectedOptions);
	};

	const arrayMove = (array: any, from: any, to: any) => {
		array = array.slice();
		array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
		return array;
	};

	const onSortEnd = (oldIndex: any, newIndex: any) => {
		const newValue = arrayMove(selectedLists, oldIndex, newIndex);
		setSelectedLists(newValue);
	};

	const savePrayer = () => {
		let editedPost = {
			id: currentPrayer?.postId ?? "00000000-0000-0000-0000-000000000000",
			title: prayerTitle,
			content: prayerContent,
			listIds: selectedLists
				.filter((l) => l != null)
				.map((v: any, i: any) => {
					return v.value;
				}),
			status: "Active",
			parentPostId: null,
			typeKey: 1,
		};

		saveUserPost(userProfile.userDataId, editedPost).then((res: any) => {
			dispatch(fetchUserPosts(userProfile.userDataId));
			dispatch(fetchUserComments(userProfile.userDataId));

			history.goBack();
			// history.push('/prayers');
		});
	};

	const cancelPrayer = () => {
		history.goBack();
		// history.push('/prayers');
	};

	return (
		<Fragment>
			<div
				className="subheader py-2 py-lg-6 subheader-transparent"
				id="kt_subheader"
			>
				<PageTitle title="NewPrayer" />
			</div>
			<Card className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
				<Card.Body>
					<Row>
						<Col>
							<form className="form">
								<div className="card-body text-left">
									<Row>
										<Col xs="12">
											<div className="form-group">
												<label>Title</label>
												<input
													type="text"
													className="form-control form-control-lg form-control-solid"
													name="postcode"
													placeholder="Prayer title"
													value={prayerTitle}
													onChange={(e) => setPrayerTitle(e.target.value)}
												/>
											</div>
										</Col>
										<Col xs="12">
											<div className="form-group">
												<label htmlFor="exampleTextarea">Your Prayer</label>
												<Editor
													apiKey="ywcdmew15is3akz9f8ux261kleua40dj27s9dbkhdlct3b08"
													init={{
														height: "300",
														menubar: false,
														plugins: [
															"advlist autolink lists link image",
															"charmap print preview anchor help",
															"searchreplace visualblocks code",
															"insertdatetime media table paste wordcount",
														],
														toolbar:
															"bold italic | alignleft aligncenter alignright | bullist numlist outdent indent",
													}}
													initialValue={currentPrayer?.content ?? ""}
													onEditorChange={(content) =>
														setPrayerContent(content)
													}
												/>
											</div>
										</Col>
										<Col md="4">
											<div className="form-group">
												<label>
													Expiration date <small>Optional</small>
												</label>
												<br />
												<DatePicker
													className="form-control"
													selected={startDate}
													onChange={(date) => setStartDate(date as Date)}
													minDate={
														new Date(
															today.getFullYear(),
															today.getMonth(),
															today.getDate() + 7
														)
													}
													maxDate={
														new Date(
															today.getFullYear(),
															today.getMonth(),
															today.getDate() + 97
														)
													}
												/>
											</div>
										</Col>
										<Col md="8">
											{sharableLists && sharableLists.length > 0 && (
												<div className="form-group">
													<label>
														Share to list(s) <small>Optional</small>
													</label>
													<SortableSelect
														useDragHandle
														// react-sortable-hoc props:
														axis="xy"
														onSortEnd={onSortEnd}
														distance={4}
														// small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
														getHelperDimensions={({ node }) =>
															node.getBoundingClientRect()
														}
														// react-select props:
														isMulti
														options={sharableLists.map((i: any, n: number) => {
															return { value: i.id, label: i.name };
														})}
														value={selectedLists}
														onChange={onChange}
														components={{
															MultiValue: SortableMultiValue,
															MultiValueLabel: SortableMultiValueLabel,
														}}
														closeMenuOnSelect={false}
													/>
												</div>
											)}
										</Col>
									</Row>
									<div className="card-footer text-center">
										<Button
											type="reset"
											className="bg-white text-center text-gray font-xsss fw-600 p-2 m-2 rounded-3 d-inline-block"
											onClick={() => cancelPrayer()}
										>
											Cancel
										</Button>
										<Button
											type="button"
											className="bg-red text-center text-white font-xsss fw-600 p-2 m-2 rounded-3 d-inline-block"
											onClick={() => savePrayer()}
										>
											Save
										</Button>
									</div>
								</div>
							</form>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Fragment>
	);
};

export default EditPost;

import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { setUITheme } from "../../store/Navigation/NavSlice";

export const DarkModeButton: React.FC = () => {
	let dispatch = useDispatch();

	const { uiTheme } = useSelector((state: RootState) => state.nav);

	let clickedClass = "clicked";
	const body = document.body;
	const lightTheme = "theme-light";
	const darkTheme = "theme-dark";

	if (uiTheme === lightTheme || uiTheme === darkTheme) {
		body.classList.add(uiTheme);
	} else {
		body.classList.add(lightTheme);
	}

	const switchTheme = (e: any) => {
		if (uiTheme === darkTheme) {
			body.classList.replace(darkTheme, lightTheme);
			e.target.classList.remove(clickedClass);
			dispatch(setUITheme("theme-light"));
		} else {
			body.classList.replace(lightTheme, darkTheme);
			e.target.classList.add(clickedClass);
			dispatch(setUITheme("theme-dark"));
		}
	};

	return (
		<span
			className={`pointer p-2 text-center ms-3 menu-icon chat-active-btn ${
				uiTheme === "dark" ? clickedClass : ""
			}`}
			onClick={(e) => switchTheme(e)}
		>
			<i className="feather-moon font-xl text-current"></i>
		</span>
	);
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { RootState } from "../../store";
import { DarkModeButton } from "./DarkModeButton";

const Header: React.FC = () => {
	const dispatch = useDispatch();

	const { uiTheme } = useSelector((state: RootState) => state.nav);

	const [isOpen, set_isOpen] = useState<boolean>(false);
	const [isActive, set_isActive] = useState<boolean>(false);
	const [isNoti, set_isNoti] = useState<boolean>(false);
	const [logoImage, set_logoImage] = useState<string>("");

	const navClass = `${isOpen ? " nav-active" : ""}`;
	const buttonClass = `${isOpen ? " active" : ""}`;
	const searchClass = `${isActive ? " show" : ""}`;

	const toggleOpen = () => set_isOpen(!isOpen);
	const toggleActive = () => set_isActive(!isActive);
	const toggleisNoti = () => set_isNoti(!isNoti);

	useEffect(() => {
		switch (uiTheme) {
			default:
			case "theme-light":
				set_logoImage("/assets/logo/logo-dark.png");
				break;
			case "theme-dark":
				set_logoImage("/assets/logo/logo-white.png");
				break;
		}
	}, [uiTheme]);

	return (
		<div className="nav-header bg-white shadow-xs border-0">
			<div className="nav-top">
				<Link to="/">
					<img src={logoImage} style={{ width: 260 }} alt="image" />
				</Link>
				<span
					onClick={toggleActive}
					className="ms-auto me-2 menu-search-icon mob-menu"
				>
					<i className="feather-search text-grey-900 font-sm btn-round-md bg-greylight"></i>
				</span>
				<button
					onClick={toggleOpen}
					className={`nav-menu me-0 ms-2 ${buttonClass}`}
				></button>
			</div>

			<div className="ms-auto">
				<DarkModeButton />
			</div>
			<Link to="/account" className="p-0 ms-3 menu-icon">
				<img src="/assets/images/avatar.png" alt="user" className="w40 mt--1" />
			</Link>

			<nav className={`navigation scroll-bar ${navClass}`}>
				<div className="container ps-0 pe-0">
					<div className="nav-content">
						<div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
							<div className="nav-caption fw-600 font-xss text-grey-500">
								<span>Prayer </span>Lists
							</div>
							<ul className="mb-1 top-content">
								<li className="logo d-none d-xl-block d-lg-block"></li>
								<li>
									<Link to="/" className="nav-content-bttn open-font">
										<i className="feather-home btn-round-md bg-blue-gradiant me-3"></i>
										<span>Home</span>
									</Link>
								</li>
								<li>
									<Link
										to="/all-prayers"
										className="nav-content-bttn open-font"
									>
										<i className="fa-regular fa-person-praying btn-round-md bg-primary-gradiant me-3"></i>
										<span>All Prayers</span>
									</Link>
								</li>
								<li>
									<Link to="/my-prayers" className="nav-content-bttn open-font">
										<i className="fa-solid fa-hands-praying btn-round-md bg-primary-gradiant me-3"></i>
										<span>My Prayers</span>
									</Link>
								</li>
								<li>
									<Link to="/my-lists" className="nav-content-bttn open-font">
										<i className="fa-solid fa-list-ul btn-round-md bg-primary-gradiant me-3"></i>
										<span>My Lists</span>
									</Link>
								</li>
								<li>
									<Link to="/new-prayer" className="nav-content-bttn open-font">
										<i className="fa-solid fa-plus btn-round-md bg-primary-gradiant me-3"></i>
										<span>New Prayer</span>
									</Link>
								</li>
							</ul>
						</div>

						<div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
							<div className="nav-caption fw-600 font-xss text-grey-500">
								<span></span> Account
							</div>
							<ul className="mb-1">
								<li className="logo d-none d-xl-block d-lg-block"></li>
								<li>
									<Link
										to="/settings"
										className="nav-content-bttn open-font h-auto pt-2 pb-2"
									>
										<i className="font-sm feather-settings me-3 text-grey-500"></i>
										<span>Settings</span>
									</Link>
								</li>
								<li>
									<Link
										to="/defaultanalytics"
										className="nav-content-bttn open-font h-auto pt-2 pb-2"
									>
										<i className="font-sm feather-pie-chart me-3 text-grey-500"></i>
										<span>Analytics</span>
									</Link>
								</li>
								<li>
									<Link
										to="/logout"
										className="nav-content-bttn open-font h-auto pt-2 pb-2"
									>
										<i className="fa-solid fa-right-from-bracket me-3 text-grey-500"></i>
										<span>Logout</span>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>

			<div className={`app-header-search ${searchClass}`}>
				<form className="search-form">
					<div className="form-group searchbox mb-0 border-0 p-1">
						<input
							type="text"
							className="form-control border-0"
							placeholder="Search..."
						/>
						<i className="input-icon">
							{/* <ion-icon
								name="search-outline"
								role="img"
								className="md hydrated"
								aria-label="search outline"
							></ion-icon> */}
						</i>
						<span className="ms-1 mt-1 d-inline-block close searchbox-close">
							<i className="ti-close font-xs" onClick={toggleActive}></i>
						</span>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Header;

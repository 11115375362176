import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getListCategories } from "../../../services/apiService";
import { ListParams } from "../ManageList";

export const ManageListCategories: React.FC = () => {
	const { listId } = useParams<ListParams>();
	const [categories, setCategories] = useState<any>();

	useEffect(() => {
		if (listId) {
			getListCategories(listId).then((resp) => {
				setCategories(resp);
			});
		}
	}, [listId]);

	return (
		<div>
			<h5>Manage List Categories</h5>
			<div className="left">
				<h6>Categories</h6>
				{categories ? (
					<ul>
						{categories.map((cat: any, index: number) => {
							return <li key={index}>{cat.name}</li>;
						})}
					</ul>
				) : (
					<span>No categories defined</span>
				)}
				<h6>TODO</h6>
				<ul>
					<li>Add/Rename Category</li>
					<li>Delete? - Only if empty</li>
					<li>Activate/Deactivate - using status</li>
					<li>Display Stats (# prayers, # total views, etc.)</li>
				</ul>
			</div>
			<div className="center">
				<Button
					id="btnUnsubscribe"
					type="button"
					color="primary"
					className="btn btn-sm btn-light-primary"
					title="Unsubscribe"
				>
					<i className="fas fa-plus-square"></i>Add Category
				</Button>
			</div>
		</div>
	);
};

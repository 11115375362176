import { Fragment, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { removePostFromList } from "../../../services/apiService";
import { RootState } from "../../../store";
import {
	fetchUserComments,
	fetchUserPosts,
} from "../../../store/User/UserSlice";

type RemoveFromListProperties = {
	postId: string;
	listid: string;
	listName: string;
};

export const RemoveFromList: React.FC<RemoveFromListProperties> = ({
	postId,
	listid,
	listName,
}) => {
	const dispatch = useDispatch();

	const [canRemove, setCanRemove] = useState(false);
	const [unshareModal, setUnshareModal] = useState(false);

	const toggleUnshareModal = () => setUnshareModal(!unshareModal);

	const { userLists, userProfile } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		if (userLists && listid) {
			let found = userLists.find((l) => l.id === listid);
			setCanRemove(found?.canPost ?? false);
		}
	}, [userLists, listid]);

	const removeFromList = () => {
		removePostFromList(postId, listid).then((res: any) => {
			dispatch(fetchUserPosts(userProfile.userDataId));
			dispatch(fetchUserComments(userProfile.userDataId));
		});
	};
	return (
		<Fragment>
			<Modal show={unshareModal} toggle={toggleUnshareModal} className="">
				<Modal.Header>
					<span className="text-dark-100 font-size-xl text-hover-primary font-weight-bolder">
						Remove from list {listName}
					</span>
				</Modal.Header>
				<ModalBody>
					This will remove this prayer from the {listName} list, but the prayer
					will still be active.
				</ModalBody>
				<ModalFooter>
					<Button
						className="bg-white text-center text-gray font-xsss fw-600 p-2 rounded-3 d-inline-block"
						onClick={toggleUnshareModal}
					>
						Cancel
					</Button>
					<Button
						className="bg-red-gradiant text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block"
						onClick={() => {
							removeFromList();
							toggleUnshareModal();
						}}
					>
						Remove from list
					</Button>
				</ModalFooter>
			</Modal>
			{canRemove ? (
				<Link to="#" onClick={toggleUnshareModal}>
					{listName}
				</Link>
			) : (
				<span>{listName}</span>
			)}
		</Fragment>
	);
};

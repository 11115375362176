import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { List } from "../../@types/list.d";
import { getPublicLists } from "../../services/apiService";

export const fetchPublicLists = createAsyncThunk(
	"lists.public.fetch",
	async () => {
		try {
			return getPublicLists();
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

const ListSlice = createSlice({
	name: "ListSlice",
	initialState: {
		publicLists: [] as Array<List>,
		loading: false,
	},
	reducers: {
		setPublicLists(state, action: PayloadAction<string>) {
			state.publicLists = JSON.parse(action.payload) as Array<List>;
		},
	},
	extraReducers: (builder) => {
		// User profile Get API Status Reducers
		builder.addCase(fetchPublicLists.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchPublicLists.fulfilled, (state, action) => {
			state.publicLists = action.payload as Array<List>;
			state.loading = false;
		});

		builder.addCase(fetchPublicLists.rejected, (state, action) => {
			console.log(
				`Failed to fetch public lists Status Code: ${action.payload}`
			);
			state.loading = false;
		});
	},
});

export const { setPublicLists } = ListSlice.actions;

export default ListSlice.reducer;

import { Route } from "react-router-dom";

import EditPost from "./components/EditPost";

import "react-datepicker/dist/react-datepicker.css";

const EditPrayer = (props: any) => {
	return (
		<Route>
			<Route path="/edit-prayer/:postId" children={<EditPost />} />
			<Route exact path="/new-prayer" children={<EditPost />} />
		</Route>
	);
};

export default EditPrayer;

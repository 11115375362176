import React, { Component } from "react";
import { Link } from "react-router-dom";

const Appfooter: React.FC = () => {
	return (
		<div className="app-footer border-0 shadow-lg bg-primary-gradiant">
			<Link to="/" className="nav-content-bttn nav-center">
				<i className="feather-home"></i>
			</Link>
			<Link to="/all-prayers" className="nav-content-bttn">
				<i className="fa-regular fa-person-praying"></i>
			</Link>
			<Link to="/my-prayers" className="nav-content-bttn" data-tab="chats">
				<i className="fa-solid fa-hands-praying"></i>
			</Link>
			<Link to="/my-lists" className="nav-content-bttn">
				<i className="fa-solid fa-list-ul"></i>
			</Link>
			<Link to="/settings" className="nav-content-bttn">
				<img
					src="/assets/images/avatar.png"
					alt="user"
					className="w30 shadow-xss"
				/>
			</Link>
		</div>
	);
};

export default Appfooter;

import { Fragment, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

import { List } from "../../../@types/list.d";

import { addPostToList, getListCategories } from "../../../services/apiService";
import { RootState } from "../../../store";
import {
	fetchUserComments,
	fetchUserPosts,
} from "../../../store/User/UserSlice";
import { Post } from "../../../@types/post.d";

type ShareWithListProperties = {
	post: Post;
	list: List;
};

export const ShareWithList: React.FC<ShareWithListProperties> = ({
	post,
	list,
}) => {
	const dispatch = useDispatch();

	const [shareModal, setShareModal] = useState<boolean>(false);
	const [expDate, setExpDate] = useState<Date | null>(null);
	const [catId, setCatId] = useState<string | null>(null);
	const [categories, setCategories] = useState<any>();
	const [today] = useState<Date>(new Date());

	const toggleShareModal = () => setShareModal(!shareModal);

	const { userProfile } = useSelector((state: RootState) => state.user);

	const addToList = () => {
		let postList = {
			id: "00000000-0000-0000-0000-000000000000",
			postId: post.postId,
			listId: list.id,
			listName: list.name,
			categoryId: catId,
			expirationDate: expDate,
			status: "Active",
		};
		addPostToList(post.postId, list.id, postList).then((res: any) => {
			dispatch(fetchUserPosts(userProfile.userDataId));
			dispatch(fetchUserComments(userProfile.userDataId));
		});
	};

	useEffect(() => {
		if (post && list) {
			let postList = post.postLists.find((pl) => pl.listId === list.id);
			if (postList) {
				setCatId(postList.categoryId);
			} else {
				setCatId(null);
			}
		}
	}, [post, list]);

	useEffect(() => {
		if (list?.id) {
			getListCategories(list?.id).then((resp) => {
				setCategories(resp);
			});
		}
	}, [list]);

	return (
		<Fragment>
			<Modal show={shareModal} toggle={toggleShareModal} className="">
				<Modal.Header>
					<h2 className="text-dark-75 font-size-xl text-hover-primary font-weight-bolder">
						Share with {list.name}
					</h2>
				</Modal.Header>
				<ModalBody>
					<div className="form-group">
						<div className="d-block">
							<label className="mont-font fw-600 font-xsss mb-2">
								Expiration date
							</label>
						</div>
						<DatePicker
							className="d-block form-control"
							selected={expDate}
							onChange={(date) => setExpDate(date as Date)}
							minDate={
								new Date(
									today.getFullYear(),
									today.getMonth(),
									today.getDate() + 7
								)
							}
							maxDate={
								new Date(
									today.getFullYear(),
									today.getMonth(),
									today.getDate() + 97
								)
							}
						/>
						<span className="d-block fw-500 text-grey-500 font-xssss mt-0 mb-3 text-dark-color">
							Optional
						</span>
					</div>
					{categories && categories.length > 0 && (
						<div className="form-group">
							<div className="d-block">
								<label className="mont-font fw-600 font-xsss mb-2">
									Category
								</label>
							</div>
							<select
								className="d-block form-control pt-0"
								value={catId ?? ""}
								onChange={(e) => setCatId(e.target.value)}
							>
								<option value="">Please select a category</option>
								{categories.map((cat: any, index: number) => (
									<option key={index} value={cat.id}>
										{cat.name}
									</option>
								))}
							</select>
							<span className="d-block fw-500 text-grey-500 font-xssss mt-0 mb-3 text-dark-color">
								Optional
							</span>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						className="bg-white text-center text-gray font-xsss fw-600 p-2 rounded-3 d-inline-block"
						onClick={toggleShareModal}
					>
						Cancel
					</Button>
					<Button
						className="bg-red text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block"
						onClick={() => {
							addToList();
							toggleShareModal();
						}}
					>
						Share To List
					</Button>
				</ModalFooter>
			</Modal>
			<Link to="#" onClick={toggleShareModal}>
				<span></span>&nbsp;&nbsp;{list.name}
			</Link>
		</Fragment>
	);
};

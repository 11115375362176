import { Fragment } from "react";
import { Route, useRouteMatch } from "react-router-dom";

import { DisplayLists } from "..";

const Lists = () => {
	const { url } = useRouteMatch();

	return (
		<Fragment>
			<Route
				path="/my-lists/:id"
				children={<DisplayLists rootUrl="all-prayers" />}
			/>
			<Route
				exact
				path={url}
				children={<DisplayLists rootUrl="all-prayers" />}
			/>
		</Fragment>
	);
};

export default Lists;

import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	useLocation,
} from "react-router-dom";
import { Container } from "react-bootstrap";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import Home from "./Home";

import { Header, Spinner } from ".";

import Lists from "./lists/Lists";
import MyPosts from "./posts/MyPosts";
import Posts from "./posts/Posts";
import Profile from "./profile/Profile";
import EditPrayer from "./posts/EditPrayer";
import Team from "./Team";
import { getUser } from "../services/apiService";
import {
	setProfileStatus,
	setUserProfile,
	fetchUserPosts,
	fetchUserComments,
	fetchUserLists,
} from "../store/User/UserSlice";
import { fetchPublicLists } from "../store/List/ListSlice";
import { RootState } from "../store";
import { UserData } from "../@types/user.d";
import SignUp from "./profile/SignUp";
import { Logout } from "./Logout";
import ManageList from "./lists/ManageList";
import { JoinInitList } from "./shared/JoinInitList";
import EditList from "./lists/EditList";

import Appfooter from "./shared/components/Appfooter";

import "./Layout.scss";

export const Layout = () => {
	const dispatch = useDispatch();

	const { profileStatus } = useSelector((state: RootState) => state.user);

	const { user, isAuthenticated, getIdTokenClaims } = useAuth0();

	useEffect(() => {
		if (isAuthenticated) {
			getIdTokenClaims().then((claims) => {
				localStorage.setItem("token", claims?.__raw as string);
			});
		}
		// eslint-disable-next-line
	}, [isAuthenticated]);

	useEffect(() => {
		// Set the page title
		document.title = "Prayer Lists | Westover Church";

		// If the user is an admin, make room for the secondary header menu like this
		//document.body.classList.add('header-bottom-enabled');

		if (user) {
			getUser(user?.sub as string).then((userProfile: UserData) => {
				if (
					userProfile.firstName.length === 0 ||
					userProfile.lastName.length === 0
				) {
					dispatch(setUserProfile(JSON.stringify(userProfile)));
					dispatch(setProfileStatus("Incomplete"));
				} else {
					dispatch(setUserProfile(JSON.stringify(userProfile)));
					dispatch(setProfileStatus("Complete"));

					dispatch(fetchUserPosts(userProfile.userDataId));
					dispatch(fetchUserComments(userProfile.userDataId));
					dispatch(fetchUserLists(userProfile.userDataId));
				}
			});

			dispatch(fetchPublicLists());
		}
	}, [user]);

	if (!isAuthenticated) {
		return <Spinner />;
	}

	return (
		<Router>
			<JoinInitList />
			{profileStatus === "Unknown" && (
				<Fragment>
					<Spinner />
				</Fragment>
			)}
			{profileStatus === "Incomplete" && (
				<Fragment>
					<Container>
						<Switch>
							<Route component={SignUp} />
						</Switch>
					</Container>
				</Fragment>
			)}
			{profileStatus === "Complete" && (
				<Fragment>
					<Header />

					<div className="main-content right-chat-active">
						<div className="middle-sidebar-bottom">
							<div className="middle-sidebar-left m-auto">
								<Switch>
									<Route path="/logout" exact>
										<Logout />
									</Route>
									<Route path="/settings">
										<Profile />
									</Route>
									<Route path="/edit-prayer/:postId">
										<EditPrayer />
									</Route>
									<Route path="/new-prayer">
										<EditPrayer />
									</Route>
									<Route path="/new-list">
										<EditList />
									</Route>
									<Route path="/about">
										<Team />
									</Route>
									<Route path="/my-lists/manage/:listId">
										<ManageList />
									</Route>
									<Route path="/my-lists">
										<Lists />
									</Route>
									<Route path="/all-prayers/:listId">
										<Posts />
									</Route>
									<Route path="/all-prayers">
										<Posts />
									</Route>
									<Route path="/my-prayers">
										<MyPosts />
									</Route>
									<Route path="/">
										<Home />
									</Route>
								</Switch>
							</div>
						</div>
					</div>
				</Fragment>
			)}
			<Appfooter />
		</Router>
	);
};

export default withAuthenticationRequired(Layout, {
	onRedirecting: () => <Spinner />,
	loginOptions: {
		scope: "openid profile email",
	},
});

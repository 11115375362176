import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Card, Container, Row, Col } from "react-bootstrap";

import { Link } from "react-router-dom";

import { RootState } from "../store";

import { DisplayListTiles } from ".";
import Pagetitle from "./shared/components/Pagetitle";

const Home = () => {
	const { userLists } = useSelector((state: RootState) => state.user);

	return (
		<Fragment>
			<Pagetitle title="Welcome" />
			<Card className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
				<Card.Body className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
					<i className="text-primary fa-3x fas fa-praying-hands"></i>
					<div className="clearfix w-100"></div>
					<h4 className="fw-700 font-xsss mt-3 mb-0">
						My Prayers &amp; Responses
					</h4>
					<p className="fw-500 font-xssss text-grey-500 mt-0 mb-3"></p>
					<Link
						to="/all-prayers"
						className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-blue-gradiant font-xssss fw-700 ls-lg text-white"
					>
						View
					</Link>
				</Card.Body>
			</Card>
			<div className="p-2">
				{userLists && userLists.length > 0 && (
					<DisplayListTiles title="" rootUrl="all-prayers"></DisplayListTiles>
				)}
			</div>
		</Fragment>
	);
};

export default Home;

import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import {
	getInvitationByCode,
	joinListWithInvitationCode,
} from "../../../services/apiService";

import { RootState } from "../../../store";
import {
	fetchUserComments,
	fetchUserLists,
	fetchUserPosts,
} from "../../../store/User/UserSlice";

const JoinList = (props: any) => {
	const dispatch = useDispatch();

	const { invitationCode } = useSelector((state: RootState) => state.nav);
	const { userProfile } = useSelector((state: RootState) => state.user);

	const [inviteCode, setInviteCode] = useState("");
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	const onJoinListWithCode = async () => {
		let invitation = await getInvitationByCode(inviteCode);
		if (!invitation) {
			setModal(true);
		} else {
			joinListWithInvitationCode(userProfile.userDataId, inviteCode).then(
				(list) => {
					// Valid, now join & refresh lists
					dispatch(fetchUserPosts(userProfile.userDataId));
					dispatch(fetchUserComments(userProfile.userDataId));
					dispatch(fetchUserLists(userProfile.userDataId));
					dispatch(setInviteCode(""));
					setInviteCode("");
				}
			);
		}
	};

	useEffect(() => {
		if (invitationCode) {
			setInviteCode(invitationCode);
		}
	}, [invitationCode]);

	return (
		<Fragment>
			<Modal show={modal} toggle={toggle} className="">
				<Modal.Header>
					<span className="text-dark-75 font-size-lg text-hover-primary font-weight-bolder">
						Code Not Found
					</span>
				</Modal.Header>
				<ModalBody>
					<div>
						Sorry, but that code was not found, or may have expired. Please
						check your code and try again.
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" className="btn btn-sm" onClick={toggle}>
						OK
					</Button>
				</ModalFooter>
			</Modal>
			<Card className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
				<Card.Body>
					<h3>Join a list with a code</h3>
					<input
						type="text"
						className="form-control form-control-lg form-control-solid"
						name="postcode"
						placeholder="Invitation code"
						value={inviteCode}
						onChange={(e) => setInviteCode(e.target.value)}
					/>
					<span className="form-text text-muted">
						Please enter your invitation code.
					</span>
				</Card.Body>
				<Card.Body className="text-center">
					<Button
						id="btnSubscribe"
						type="button"
						color="primary"
						className="bg-current text-center text-white font-xsss fw-600 p-2 w150 rounded-3 d-inline-block"
						title="Join List"
						disabled={inviteCode && inviteCode !== "" ? false : true}
						onClick={() => onJoinListWithCode()}
					>
						Join List
					</Button>
				</Card.Body>
			</Card>
		</Fragment>
	);
};

export default JoinList;

import { Post } from "../../../@types/post.d";
import { DisplayPost } from "../../posts/components/DisplayPost";

type ManagePostProperties = {
	post: Post;
	index: number;
	listId: string;
	onArchive?: ((postId: string) => void) | undefined;
	onUnArchive?: ((postId: string) => void) | undefined;
};

export const ManagePost: React.FC<ManagePostProperties> = ({
	post,
	index,
	listId,
	onArchive,
	onUnArchive,
}) => {
	return (
		<div>
			<DisplayPost
				post={post}
				index={index}
				allowComments={true}
				allowEdit={true}
				allowManage={true}
				showStats={true}
				listId={listId}
				onArchive={onArchive}
				onUnArchive={onUnArchive}
			/>
		</div>
	);
};
